<template>
  <div title="termsOfServiceEn" style="background-color: #eff3f5;height: 100%;width: 100%;overflow: scroll" align="center" overflow="scroll">
    <a-card style="background-color: white;width: 1100px;margin-top: 50px;padding-left: 20px;padding-right: 20px">
      <div style="margin-top: 20px">
        <h3>
          <p style="font-size: 26px">{{ themeData.saasMerNameEn }} Service Terms and Conditions</p>
        </h3>
      </div>
      <div align="left" style="margin-top: 50px">
        <div>
          <p>
            These Terms of Use,  govern your use of the Services provided by {{ themeData.saasMerNameEn }}. Please read these Terms of Use carefully as they constitute the legal relationship between you and us.<br>
            These Terms of Use comprise of this cover page and the following:<br>
            1.     THESE TERMS<br>
            2.     WHO {{ themeData.saasMerNameEn }} IS AND HOW TO CONTACT US<br>
            3.     CHANGES TO THESE TERMS<br>
            4.     IDENTITY CHECKS & INFORMATION REQUESTS<br>
            5.     ABOUT OUR SERVICES<br>
            6.     GETTING STARTED<br>
            7.     AUTHORISED USERS<br>
            8.     COLLECTION SERVICES<br>
            9.     FX CONVERSION SERVICES<br>
            10.     PAYMENT SERVICES<br>
            11.     REVERSALS AND REJECTIONS<br>
            12.     SAFETY AND SECURITY<br>
            13.     FEES & CHARGES<br>
            14.     INTELLECTUAL PROPERTY RIGHTS<br>
            15.     CONFIDENTIALITY, PRIVACY AND USE OF DATA<br>
            16.     DISCLOSURE OF INFORMATION AND USE OF PERSONAL DATA FOR DIRECT MARKETING<br>
            17.     LIMITATION OF LIABILITY<br>
            18.     INDEMNITY<br>
            19.     TERMINATION AND SUSPENSION<br>
            20.     THIRD PARTY SERVICE PROVIDERS<br>
            21.     REPRESENTATIONS AND WARRANTIES<br>
            22.     ANTI-BRIBERY, ANTI-CORRUPTION AND SANCTIONS<br>
            23.     GENERAL<br>
            24.     DEFINITIONS & INTERPRETATION<br>
            In addition to the Terms of Use, the following Additional Documents also apply to your use of the Payment Platform and Services and should be read together with these Terms of Use:<br>
            <a @click="usePolicy">-Acceptable Use Policy</a><br>
            These Terms of Use and the Additional Documents may be supplemented or amended from time to time, and we will notify you in advance in accordance with Applicable Law and the terms of these Terms of Use. You can always see the most current version of these documents on our website . You are also advised to print or download and keep a copy of the Terms of Use for future reference.<br>
            BY REGISTERING TO USE ANY OF OUR SERVICES THROUGH OUR WEBSITE, YOU ARE AGREEING TO BE BOUND BY THESE TERMS OF USE (AS MAY BE SUPPLEMENTED OR AMENDED FROM TIME TO TIME), WHICH ARE MADE AVAILABLE TO YOU ONLINE VIA OUR WEBSITE. BY CLICKING THE BUTTON “CREATE ACCOUNT” OR SIMILAR BUTTON, REGISTERING, ACCESSING OR USING THE SERVICES (COLLECTIVELY, “INDICATED CONSENT”), YOU ENTER INTO A LEGALLY BINDING AGREEMENT WITH {{ themeData.saasMerNameEn }} AND YOU AGREE TO ACCEPT THESE TERMS OF USE. PLEASE DO NOT USE ANY OF OUR SERVICES IF YOU DO NOT ACCEPT THESE TERMS OF USE (OR ANY SUPPLEMENTS OR AMENDMENTS TO THESE TERMS OF USE).<br>
            In the event that after having given your Indicated Consent, you and {{ themeData.saasMerNameEn }} agree to execute a physical paper form of these Terms of Use, the physical paper form of these Terms of Use will also be valid, and be retroactively effective as of the date when you first give your Indicated Consent. In the unlikely event that there is inconsistency between the online version and physical form of these Terms of Use, the online version will prevail.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">1.THESE TERMS</p>
        </div>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle">1.1 Parties.</span><br>
            By using the {{ themeData.saasMerNameEn }} Portal and Services you (‘Customer/you/your’) agree to these terms applying between you and {{ themeData.saasMerNameEn }} Limited (‘{{ themeData.saasMerNameEn }}/us/we/our’). Please read these terms carefully and contact us if anything is unclear.
          </p>
          <p class="detail">
            Additional Documents. The following additional documents (which are updated from time to time) also apply to your use of the {{ themeData.saasMerNameEn }} Portal and Services and should be read together with these terms:
          </p>

          <p>(a)Acceptable Use Policy</p>
          <p>(b)Privacy Policy</p>
          <p>(c)Product Agreement</p>

          <p>
            Where we provide you with other services, you should refer to the terms and conditions of those other services.
          </p>
          <p class="detail">
            <span class="detailTitle">1.2 Access to these terms.</span>
            <br>You can always see the most current version of those documents, including these terms, on our website at {{ themeData.website }}. You can also download a copy of these documents and keep it for future reference or ask us for a copy at any time.
          </p>
          <p class="detail">
            <span class="detailTitle">1.3 Contacting us. <br></span>If we need to send you information in a form you can keep, we will either send you an email or provide information on our website or via the {{ themeData.saasMerNameEn }} Portal that you can download. Please keep copies of all communications we send to you.
          </p>
        </div>
        <p class="navigationTitle">
          2.WHO {{ themeData.saasMerNameEn }} IS AND HOW TO CONTACT US
        </p>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle">2.1 Our company information.<br></span>The Services are offered by {{ themeData.saasMerNameEn }} Limited, which is a company incorporated under the laws of Hong Kong. {{ themeData.saasMerNameEn }} is authorised by the Customs and Excise Department under the Anti-Money Laundering and Counter-Terrorist Financing Ordinance to provide remittance services and money changing services. We may provide the Services either directly or via one or more of our Group Companies or service providers.
          </p>
          <p class="detail">
            <span class="detailTitle"> 2.2 How to contact us. <br></span>You can contact us by:<br>

            (a)emailing us at: {{ themeData.website }};<br>
            (b)sending us a message through the contact links on our website {{ themeData.website }}.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 2.3 How we will contact you. <br></span>We will contact you using the contact details you provided when you registered – please keep these up to date. By using our Services, you agree to receive electronic communications from us. If we have reasonable concerns either about the security of your {{ themeData.saasMerNameEn }} Account, or any suspected or actual fraudulent use of your {{ themeData.saasMerNameEn }} Account or it associated accounts, we will contact you via telephone, email, or both (unless contacting you would be unlawful or compromise our reasonable security measures).
          </p>
        </div>
        <p class="navigationTitle">
          3.CHANGES TO THESE TERMS
        </p>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle"> 3.1 General changes.<br></span>We may change these terms by giving you one (1) month's prior written notice. You are deemed to have accepted the proposed changes if you do not terminate these terms by giving us written notice during the notice period.
          </p>
          <p class="detail">
            <span class="detailTitle"> 3.2 Urgent changes.<br></span>We may also make some changes immediately, without prior notice, if they:<br>
            (a)are required by Applicable Law; <br>
            (b)relate to the addition of a new service or extra functionality to our Services and do not affect any terms relating to the existing Services.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 3.3 If you do not agree to changes. <br></span>You should stop using our Services if you do not agree to any changes. If you keep using our Services after we have announced such a change, you are deemed to have accepted the change.
          </p>
          <p class="detail">
            <span class="detailTitle"> 3.4 Modifications.<br></span>We may modify the configuration, appearance or functionality of the {{ themeData.saasMerNameEn }} Portal at any time to facilitate the continued and proper operation of the {{ themeData.saasMerNameEn }} Portal or to comply with Applicable Law. To the extent reasonably practicable, {{ themeData.saasMerNameEn }} will provide you with advance notice if the modification would materially affect your ability to use the {{ themeData.saasMerNameEn }} Portal.
          </p>
          <p class="detail">
            <span class="detailTitle"> 3.5 Updates.<br></span>We may issue updates for the {{ themeData.saasMerNameEn }} Portal, including the {{ themeData.saasMerNameEn }} APIs, from time to time. Some updates may require you to take steps to implement them. In some cases (for example, if there are security risks), you will not be able to use the {{ themeData.saasMerNameEn }} Portal until you have implemented the update. If you have not implemented an update within 6 months of us releasing it, we may stop providing some of the Services to you or terminate these terms, by giving you two months’ prior written notice.
          </p>
        </div>
        <p class="navigationTitle">
          4.IDENTITY CHECKS & INFORMATION REQUESTS
        </p>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle"> 4.1 Identity checks.<br></span>In accordance with Applicable Law, before and from time to time, during our provision of Services to you, we will carry out customer due diligence checks on you, your directors, partners, ultimate beneficial owners, and employees (as relevant), together with any parties involved in your transactions. You must provide us with complete, accurate, and up to date information at all times, including providing personal information about your directors, partners, ultimate beneficial owners, and employees (as relevant).
          </p>
          <p class="detail">
            <span class="detailTitle">4.2 Information requests.<br></span>You will promptly provide any additional information we require at any time, at the latest within 2 Business Days of a request from us. For example, we may request information to confirm the authenticity of certain transactions. You acknowledge that we may not provide you with any of our Services until we have received all the information we require. We will not be responsible for any loss you may suffer or incur arising out of your failure to do so.</p>
          <p class="detail">
            <span class="detailTitle">4.3 Third party verification.<br></span>You agree that we may make, directly or using a third party, any inquiries we believe are necessary to verify information you provide to us, including checking commercial databases or credit reports. We may keep records of such customer due diligence checks in accordance with Applicable Law.
          </p>
          <p class="detail">
            <span class="detailTitle">4.4 Credit assessments.<br></span>4.4You agree that our provision of, and your ability to use, the Services is subject to:<br>

            (a)successful completion of the initial, and any subsequent, credit evaluations; <br>
            (b)there being no material changes to your credit status during the term of these terms.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">4.5 Acting on own account.<br></span>You confirm you are acting on your own account, not on behalf of any other person or legal entity. If you are entering into these terms as trustee of a trust, you must disclose that to us. We will deem any use of the {{ themeData.saasMerNameEn }} Account and associated accounts, including any transfers into and out of those accounts, to have been carried out by you.<br>
          </p>
        </div>
        <p class="navigationTitle">
          5.ABOUT OUR SERVICES
        </p>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle"> 5.1 {{ themeData.saasMerNameEn }} Account and Portal.<br></span>Once you have successfully registered with us, an {{ themeData.saasMerNameEn }} Account will be created for you. You can only have one {{ themeData.saasMerNameEn }} Account, unless we agree otherwise in writing. If we discover that you have more than {{ themeData.saasMerNameEn }} Account, at our discretion, we may merge or close any duplicate profiles, or terminate our Services to you, if we reasonably believe you have opened them in order to bypass any restrictions in the Services. You can use your {{ themeData.saasMerNameEn }} Account to log into and gain access to the {{ themeData.saasMerNameEn }} Portal.  The {{ themeData.saasMerNameEn }} Portal provides you with an easy to use user interface to access and use our various Services.
          </p>
          <p class="detail">
            <span class="detailTitle">5.2 Services overview.<br></span>Our Services provided under these terms include:<br>
            (a)Collection Services – where {{ themeData.saasMerNameEn }} receives funds in Supported Currency for you;<br>
            (b)Payment Services – where you can send Payments to payees; <br>
            (c)FX Conversion Services – where you can convert funds from one Supported Currency to another Supported Currency.<br>

            Each of these is a separate Service provided by {{ themeData.saasMerNameEn }}.<br>

            {{ themeData.saasMerNameEn }} is not obliged to enter into any Services with the Customer unless {{ themeData.saasMerNameEn }} has received all document or other information required as a condition of entry into a Collection, Payment, or FX Conversion Service in form and substance satisfactory to {{ themeData.saasMerNameEn }}, in its sole discretion;<br>
          </p>
          <p class="detail">
            <span class="detailTitle">5.3 Collection Services. <br></span>n connection with the Collection Services, we will provide you with local bank account credentials in Supported Currency which you may provide your payers to send funds to you. The funds collected will be recorded in the Global Account, in each Supported Currency, that we set up for you. You may, at any time, withdraw funds to your own same name account by giving the relevant withdrawal instructions to {{ themeData.saasMerNameEn }}. The funds collected for you must not stay in the Global Account for longer than the maximum period allowed under clause 8.4.
          </p>
          <p class="detail">
            <span class="detailTitle">5.4 Payment Services and FX Conversions.<br></span>{{ themeData.saasMerNameEn }} allows Customers to make single, or multiple Payments to Payees. In connection with the Payment Services and/or FX Conversion Services, {{ themeData.saasMerNameEn }} will set up a Correspondent Account into which you may send funds to pay for your Payments and/or FX Conversions instructions. {{ themeData.saasMerNameEn }} will, in turn, based on the specific instruction you give us at the time you send the funds to the Correspondent Account, remit those funds in a Supported Currency to your designated payee, and/or convert those funds from Source Currency to the Buy Currency.
          </p>
          <p class="detail">
            <span class="detailTitle">5.5 Transaction records.<br></span>Your transaction records will be recorded in the {{ themeData.saasMerNameEn }} Account which will display records of funds collected through the Global Account as part of the Collection Services, and funds paid into the Correspondent Account as part of the Payment Services and your FX Conversions.
          </p>
          <p class="detail">
            <span class="detailTitle">5.6 Deposit protection scheme.<br></span>The Hong Kong Deposit Protection Scheme (‘DPS’) does not apply to the funds sent, exchanged or received in connection with the Services. You acknowledge and agree that we are not a bank and do not provide you with a bank account, and so our Services are not covered by the DPS. You further agree that the Global Account is a ‘virtual account’ and is not a bank account, that the funds transferred to us are beneficially ours and will not be held on trust for you, and that you will not earn interest or be entitled to any interest on any funds.
          </p>
          <p class="detail">
            <span class="detailTitle">5.7 Keeping your funds safe.<br></span>To keep your funds safe, {{ themeData.saasMerNameEn }} has implemented commercially reasonable administrative and technical measures to protect and safeguard the funds collected for or received from you in connection with the Services. {{ themeData.saasMerNameEn }} will hold such funds in designated bank accounts segregated from {{ themeData.saasMerNameEn }}’s corporate funds, and will not use such funds for its own operating expenses.
          </p>
          <p class="detail">
            <span class="detailTitle"> 5.8 Restrictions.<br></span>We may place interim or permanent restrictions on the use of all or any part of the Services depending on certain regulatory requirements, your country of residence, identity verification checks or business requirements, all of which are set out in the Acceptable Use Policy or any Additional Terms. These will be communicated to you at the time the restriction is put in place or, if that is not practical due to urgent or unforeseen circumstances, promptly after the restriction is put in place.
          </p>
          <p class="detail">
            <span class="detailTitle"> 5.9 Your relationship with us.<br></span>We act as a service provider to you under these terms. Except as otherwise expressly stated herein, {{ themeData.saasMerNameEn }} does not act as a trustee, an escrow agent or stakeholder for you or your payer/payee. {{ themeData.saasMerNameEn }} assumes no liability to or in respect of any products, services or any other part of your business.
          </p>
          <p class="detail">
            <span class="detailTitle">5.10 Deposit for Services.<br></span>You shall pay to us on demand a deposit for any Services {{ themeData.saasMerNameEn }} provides such as Payment Services and / or FX Conversion Services in the amount as we may require from time to time. For the avoidance of doubt, we have the right to require you to pay additional amounts to increase the amount of the deposit at any time prior to the final settlement of such service to make sure you still have the sufficient amount to continue the service after the deductions of any applicable fees which may occur due to inter-bank charges, {{ themeData.saasMerNameEn }}’s correspondent bank charges, and / or other processing fees during the settlement of your deposit funds to us.
          </p>
          <p class="detail">
            <span class="detailTitle"> 5.11 Chosen services<br></span>
            (a)The Customer subscribes to the Services for the duration of the Subscription Period.<br>
            (b){{ themeData.saasMerNameEn }} shall provide the Services listed above pursuant to the Agreement, and this Services Schedule. The Subscription Period for each of the subscribed Services, as applicable, shall be renewed automatically for another 12 months, unless the Customer provides {{ themeData.saasMerNameEn }} with a written notice 30 days prior to the end of the Subscription Period explicitly stating the Customer's desire not to renew it.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">5.12 Operating time<br></span>
            {{ themeData.saasMerNameEn }}’s working hours: Beijing time 9:30 am to 12:00 am, 13:00 pm to 16:00 pm, on each business day, excluding any holidays of Mainland China, Hong Kong and / or any other jurisdictions that {{ themeData.saasMerNameEn }} or its third party provider operates or any days that are not business days.<br>
          </p>
        </div>
        <p class="navigationTitle">
          6.GETTING STARTED
        </p>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle">6.1 Creating your {{ themeData.saasMerNameEn }} Account.<br></span>In order to use our Services, you must provide the necessary information as prompted through the {{ themeData.saasMerNameEn }} Portal to register and create your {{ themeData.saasMerNameEn }} Account. If required by {{ themeData.saasMerNameEn }}, you must provide your bank account details to be linked to your {{ themeData.saasMerNameEn }} Account.
          </p>
          <p class="detail">
            <span class="detailTitle">6.2 Creating a Global Account.<br></span>6.2Once we have created your {{ themeData.saasMerNameEn }} Account, you can submit a request to open a Global Account by logging into the {{ themeData.saasMerNameEn }} Portal and providing the necessary information, including any additional information that we may require from time to time. You will also need to agree to any Additional Terms. We will advise you of this requirement, if relevant, when you request to open a Global Account.
          </p>
          <p class="detail">
            <span class="detailTitle"> 6.3 Setting up {{ themeData.saasMerNameEn }} APIs.<br></span>You may access the {{ themeData.saasMerNameEn }} Portal through {{ themeData.saasMerNameEn }} APIs as well as the MSP. However, in order to use our Services through the {{ themeData.saasMerNameEn }} APIs, you must first be approved by us. If you are approved by us, you will need to comply with our API Documentation and any reasonable directions regarding the integration and use of the {{ themeData.saasMerNameEn }} APIs.
          </p>
          <p class="detail">
            <span class="detailTitle"> 6.4 Information verification and Testing.<br></span>We will only create your {{ themeData.saasMerNameEn }} Account and allow you to use our Services if we are satisfied with the information you have provided, and any subsequent information we have reasonably requested from time to time, and we have been able to verify this information to our satisfaction. Prior to us accepting you as a customer, we may create a Global Account and provide you access to the {{ themeData.saasMerNameEn }} Portal (whether through {{ themeData.saasMerNameEn }} APIs or MSP) for testing purposes. In addition, we may ask you to transmit a small amount to the Global Account for testing or verification purposes. For the avoidance of doubt, you are not deemed to be our customer and you will not be able to use any of our Services unless and until we confirm that you have been accepted as our customer, and any amounts you provide to us for testing or verification purposes will be refunded to you in full if we do not accept you as a customer.
          </p>
        </div>
        <p class="navigationTitle">7.AUTHORISED USERS<br></p>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle"> 7.1 Authorised User.<br></span>You may appoint an Authorised User to act on your behalf to enter into and confirm FX Conversions and Payments under these terms. You must set up each Authorised User with a User Profile and promptly provide us with the following details of any of your proposed Authorised Users: Customer name, name of Authorised User, Customer address and billing address, phone and email address of Authorised User, a board resolution or authorization letter, and any other contact or identification information of the Authorised User that we may reasonably require. You will ensure that your Authorised Users comply with these terms and in respect of your obligations and liabilities under these terms, references to ‘you’ shall (where the context requires) be read as including your Authorised Users.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 7.2 Instructions.<br></span>We will receive instructions from your Authorised User(s) through the {{ themeData.saasMerNameEn }} Portal via the MSP or {{ themeData.saasMerNameEn }} APIs or, under prior arrangements agreed with us, manually via a representative of {{ themeData.saasMerNameEn }}.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">7.3 Acknowledgement.<br></span>You agree that:<br>

            (a)your Authorised Users have the authority to provide instructions for the provision of Services to us on your behalf;<br>
            (b)we will rely on the authority of the Authorised User, and you will be bound by the actions of your Authorised Users, until you provide us with written notice withdrawing or otherwise varying the authority of an Authorised User;<br>
            (c)you are responsible for ensuring that the appropriate person(s) are accorded the necessary authority to act as your Authorised Users;<br>
            (d)we may refuse access to your Authorised User(s) if we are concerned about unauthorised or fraudulent access; <br>
            (e)you will promptly report to us any infringements or unauthorised access to the {{ themeData.saasMerNameEn }} Portal, including the MSP or {{ themeData.saasMerNameEn }} APIs.<br>
          </p>
        </div>
        <p class="navigationTitle">8.COLLECTION SERVICES<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle"> 8.1 Collection of funds through {{ themeData.saasMerNameEn }}.<br></span>If we have approved a Supported Currency for you, you may receive funds in that Supported Currency through {{ themeData.saasMerNameEn }} in accordance with these terms. Each collection is an individual contract between you and {{ themeData.saasMerNameEn }}.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 8.2 Your right to withdraw collected funds.<br></span>8.2{{ themeData.saasMerNameEn }} will collect and hold the collected funds in one or more underlying bank accounts in its name for the designated purpose of providing the Collection Services. The funds in any underlying bank account will be comingled and pooled together with those of other users of Services offered by {{ themeData.saasMerNameEn }}. You are entitled to be paid the funds collected for you as part of the Collection Services provided to you. {{ themeData.saasMerNameEn }} must pay to you such funds when you request a withdrawal, subject to the terms of these terms.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 8.3 Recording receipt of funds.<br></span>We will update your Global Account only when we receive the funds. We are not responsible for any funds transferred and will not record them as available in the Global Account, until after we have actually received the funds. The transfer of funds from your payers to {{ themeData.saasMerNameEn }}’s designated underlying account is a service provided by third parties and is not part of our Services. We have no control over the time it may take for the transfer of such funds to clear and settle.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 8.4 Withdrawals from the Global Account.<br></span>You must promptly instruct us to make a withdrawal with respect to funds received in the Global Account. If you do not give us withdrawal instructions within a reasonable period of time in any event within a maximum period of no longer than 90 days or as determined by us, we will, at our sole discretion: <br>
            (a) return the funds to the sender;<br>
            (b) remit the funds to your linked bank account as notified to us; <br>
            (c) remit the funds to you in any other way permitted by Applicable Law.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 8.5 Source of funds.<br></span>Unless we agree otherwise in writing, you must ensure that all funds collected for you come from a legitimate business source (for example, proceeds from the sale of goods or services) and in accordance with our Acceptable Use Policy. You agree not to transfer (for example, top-up) any of your own funds into the Global Account at any time. If we ask you to, you agree to promptly provide evidence of the funding source.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">8.6 Restriction on depositing funds into the Global Account.<br></span>The Global Account is used to provide you with Collection Services only. You are not permitted to transfer your own funds into the Global Account.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">8.7 Acting as limited agent.<br></span>You represent and warrant to {{ themeData.saasMerNameEn }} that each payment you receive through the Collection Services is solely in payment for your provision of bona fide goods and/or services to your customers (each, a ‘Payor’). For Collection Services, you hereby designate {{ themeData.saasMerNameEn }}, and {{ themeData.saasMerNameEn }} hereby agrees to serve, as your limited agent for the sole purpose of receiving such payments on your behalf from your Payors. You agree that upon {{ themeData.saasMerNameEn }} receiving payment from a Payor:<br>
            (a) you shall be deemed to have received payment from such Payor, <br>
            (b) such Payor’s obligation to you in connection with such payment shall be satisfied in full, <br>
            (c) any claim you have for such payment against such Payor shall be extinguished and (d) you are obligated to deliver the applicable goods and/or services to the Payor (if not already delivered), in each case regardless of whether or when {{ themeData.saasMerNameEn }} remits such payment to you. {{ themeData.saasMerNameEn }} will remit to you in accordance with these terms, or apply as an offset to any obligation you may have to {{ themeData.saasMerNameEn }}, any such payments it receives on your behalf. You shall identify to your Payors that {{ themeData.saasMerNameEn }} is acting as your agent for purposes of receiving payment on your behalf. Any receipt provided to the Payor shall be binding on you and shall satisfy all applicable regulatory requirements. This clause 8.7 states the entirety of {{ themeData.saasMerNameEn }}’s duties as your agent for receipt of payment under the Collection Services, and no other duties shall be implied by {{ themeData.saasMerNameEn }}’s undertaking to act in that capacity.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">8.8 How are balances represented in the Global Account. <br></span>The funds collected in the Global Account will be represented as part of the balance for each Supported Currency in the {{ themeData.saasMerNameEn }} portal so there will not be a separate balance shown to the Customer in the {{ themeData.saasMerNameEn }} portal for a Global Account.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">8.9 Request to open Global Account.<br></span>The Customer may submit a request to open a Global Account by logging on to the  {{ themeData.saasMerNameEn }} portal, and provide, as prompted, the required information on the portal, which includes, but is not limited to, the following information:<br>
            (a)the jurisdiction, marketplace, and currency of the requested Global Account; <br>
            (b)any further information required over the portal or as may be requested by {{ themeData.saasMerNameEn }} from time to time prior to the creation of the Global Account. The Global Account will only be created when {{ themeData.saasMerNameEn }} is satisfied that the Customer has provided sufficient details and information to open a Global Account for the Customer, and {{ themeData.saasMerNameEn }} has been able to verify this information. {{ themeData.saasMerNameEn }} reserves the right to refuse to open a Global Account for the Customer.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">8.10 How to transfer funds into the Global Account<br></span>
            (a)The Customer will need to log in to the {{ themeData.saasMerNameEn }} Portal and obtain the virtual/subsidiary account details for the Global Account in the relevant Supported Currency. {{ themeData.saasMerNameEn }} is not responsible for the funds transferred until {{ themeData.saasMerNameEn }} has actually received those funds. <br>
            (b)The Customer or any Payer may transfer funds into the Global Account through a bank transfer using virtual/subsidiary account details as indicated by {{ themeData.saasMerNameEn }} on the {{ themeData.saasMerNameEn }} Portal or in any other method permitted by {{ themeData.saasMerNameEn }} through the {{ themeData.saasMerNameEn }} Portal from time to time.  <br>
            (c)The transfer of funds into the Global Account is not part of a Service provided by {{ themeData.saasMerNameEn }} and is a service provided by third parties to the Customer. {{ themeData.saasMerNameEn }} is not responsible for failed transfers that are outside of the control of {{ themeData.saasMerNameEn }}. The Global Account is provided to customer only for the purpose of receiving funds from E-commerce platforms or counter parties under the background of legitimate and compliant goods trading or service providing, and thus the Global Account provided to the customer can only be used for credits. Under such circumstances, unless otherwise agreed by {{ themeData.saasMerNameEn }}, the customer is not allowed to use such account for any direct debit services including but not limited to regular automatic debits of utility bills or rents, refunds of sales proceeds, regular service fee payments and/or etc. Whenever there is any direct debit transaction occurred with regard to any Global Account provided to the customer, {{ themeData.saasMerNameEn }} shall have the rights to deny or terminate the transaction without notification to the customer.<br>
          </p>
        </div>
        <p class="navigationTitle">9.FX CONVERSION SERVICES<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">9.1 Your instructions for FX Conversions.<br></span>9.1You may instruct us to make a FX Conversion by providing the necessary details as prompted in the {{ themeData.saasMerNameEn }} Portal following below steps: <br>

            (a)The Customer shall submit a Customer Request relating to a FX Transaction through the {{ themeData.saasMerNameEn }} Portal, which includes, but is not limited to, the following information: (i) Currency pairings for the Buy Currency and Sell Currency; (ii)The amount of the Buy Currency and Sell Currency;  (iii)the Settlement Date (where applicable); and (iv)any further information required over the {{ themeData.saasMerNameEn }} portal, as may be requested by {{ themeData.saasMerNameEn }} from time to time prior to the issue of a Booking Confirmation.<br>
            (b)Following the receipt of a Customer Request, {{ themeData.saasMerNameEn }} shall subsequently transmit a Booking Confirmation confirming the details of the Customer Request. This Booking Confirmation shall contain the finalised FX Exchange rate, which may differ from the FX Exchange rate first quoted to the Customer. {{ themeData.saasMerNameEn }} shall endeavour, as far as is reasonably practicable, that the finalised FX Exchange rate, as provided by {{ themeData.saasMerNameEn }} correspondent bank, or liquidity provider, closely reflects the FX Exchange rate first quoted to the Customer. <br>
            (c)If a date specified the Booking Confirmation is not a Business Day, then the date will be adjusted to the first following day that is a Business Day.<br>
            (d)FX Transactions shall be created and become binding when {{ themeData.saasMerNameEn }} is satisfied that the Customer has provided sufficient particulars and information for completion of a Booking Confirmation.<br>
            (e)The Booking Confirmation may be sent by {{ themeData.saasMerNameEn }} via the Merchant Services Platform, FX API, or any other method deemed appropriate by {{ themeData.saasMerNameEn }} in its sole discretion. <br>
            (f)The issue of a Booking Confirmation to the Customer means that the trade has been booked by the Customer.<br>

          </p>
          <p class="detail">
            <span class="detailTitle">9.2 Confirmation for FX Conversions.<br></span>When we are satisfied your instructions are complete, we will issue you with a Confirmation with respect to the FX Conversion. We may decline your instructions if you do not have sufficient funds by the Settlement Cut-Off, you exceed any applicable limits, you request a FX Conversion which we deem to be a leveraged foreign exchange transaction, or in accordance with clause 19. Once a Confirmation is issued, the FX Conversion cannot be cancelled by you and is considered final. You must then settle with us the full amount of the FX Conversion in the relevant source currency in accordance with the terms of these terms.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">9.3 FX Exchange Rate.<br></span>You may access the indicative FX Exchange Rate for a FX Conversion through the {{ themeData.saasMerNameEn }} Portal. The indicative FX Exchange Rate will be quoted to you when you instruct us through the {{ themeData.saasMerNameEn }} Portal and the final FX Exchange Rate will be confirmed when we issue a Confirmation. We will ensure, as far as reasonably practicable, that the confirmed FX Exchange Rate reflects the rate quoted to you but you acknowledge that this is not always possible. The final FX Exchange Rate used to effect the FX Conversion may be different as the rate may have changed between the time of your instruction and the time we issue the Confirmation. You agree that changes to exchange rates come into effect immediately without notice.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">9.4 Settlement requirements for FX Conversion.<br></span>You must pay sufficient funds into the Correspondent Account in the relevant source currency to cover the full amount of any FX Conversion you wish to make on or before the Settlement Cut-Off. You may fund the FX Conversion by direct bank transfer to us or by other means acceptable to us. Without prejudice to any of our rights under these terms, you shall be obliged to deliver such amounts to the account designated by us within two (2) Business Days or based on the Settlement Date of our demand and you specifically acknowledge that if you fail to pay any deposit required by us in respect of any FX Conversion transaction or have not paid sufficient funds into the Correspondent Account to cover the full amount, we may cancel the FX Conversion transaction without prior notice to you and apply the deposit to the payment of any amounts due to us.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">9.5 Execution time for FX Conversions.<br></span>If you ask us to make a FX Conversion, we will do so as soon as reasonably practicable and, in any event, generally within 24 hours after we accept your instructions.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">9.6 Requirements for Spot Deal FX Conversions.<br></span>Requirements for Spot Deal FX Conversions.<br>
          </p>
        </div>
        <p class="navigationTitle">10.PAYMENT SERVICES<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">10.1 Your instructions for Payments. <br></span>You may instruct us to make a Payment by providing the necessary details as prompted in the {{ themeData.saasMerNameEn }} Portal. If a Payment involves currency conversions between Supported Currencies (e.g. the source currency is different from the payout currency), we will also provide you with our FX Conversion Services as an ancillary service to the Payment Services. Each Payment, including any Payment with FX Conversion, is an individual contract between you and {{ themeData.saasMerNameEn }}.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">10.2 Confirmation of Payments.<br></span>When we are satisfied your instructions are complete, we will issue you with a Confirmation with respect to the Payment. We may decline your instructions if you do not have sufficient funds by the Settlement Cut-Off, you exceed any applicable limits, or accordance with clause 19. Once a Confirmation is issued, the Payment cannot be cancelled by you and is considered final. You must then settle with us the full amount of the Payment in accordance with the terms of these terms.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">10.3 Settlement requirements for Payment Services.<br></span>You must pay sufficient funds into the Correspondent Account in the relevant source currency to cover the full amount of any Payment you wish to make on or before the Settlement Cut-Off. You may pay us by way of direct bank transfer to us or by other means acceptable to us. Without prejudice to any of our rights under these terms, you specifically acknowledge that if you do not have sufficient funds in your Correspondent Account to cover the full amount, we may deduct or set-off such amount against any funds collected and held for you in your Global Account or any balance in your {{ themeData.saasMerNameEn }} Portal. If you transfer funds into the Correspondent Account in a currency which is not the Payment currency or if the funds held in your Global Account or any currency in your {{ themeData.saasMerNameEn }} Portal (which we use for the purposes of set-off) are not in the Payment currency, you authorise us to, on the date of Payment, convert such currency into the Payment currency at our standard exchange rates which are available upon request.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">10.4 Execution time for Payments Services.<br></span>If you ask us to make a Payment, we will make the Payment as soon as reasonably practicable and, in any event, generally within 24 hours after we accept your instructions. The methods of crediting the funds may be provided by third parties (for example, the bank where the payee has their bank account) and are not part of our Services. We do not have any control over the amount of time it may take for a payee’s bank or payment provider to credit funds to the payee, but would generally expect this to take less than 5 Business Days, unless a longer period is required by us to comply with Applicable Law. If a date specified the Payment Confirmation is not a Business Day, then the date will be adjusted to the first following day that is a Business Day.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">10.5 Instruction for Payments Services.<br></span>

            (a)The Customer shall submit a Customer Request relating to a Payment through the {{ themeData.saasMerNameEn }} Portal, which includes, but is not limited to, the following information:  (i)Know Your Customer ("KYC") details, as prescribed for the Customer;  (ii)KYC details, as prescribed for the Payor and Payee;  (iii)bank account details of the Payee; (iv)Source Currency and Payment Currency; (v)Payment amounts; (vi)Payment Date;  (vii)the nominated Payment method(where a Payment method is not nominated, {{ themeData.saasMerNameEn }} reserves the right to use any Payment method at its discretion, to process the Payment). <br>
            (b)Payments shall be created and become binding when {{ themeData.saasMerNameEn }} is satisfied that the Customer has provided sufficient particulars, supporting documents and information for completion of a Payment Confirmation.<br>
          </p>
        </div>
        <p class="navigationTitle">11.REVERSALS AND REJECTIONS<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">11.1 Reversals.<br></span>We may need to reverse any funds sent or received in connection with the Services, and either deduct funds we have received as part of the Services or return funds we have received as part of the Services, including in the following circumstances:<br>

            (a)the sender, or any relevant payment services provider, reverses a transaction or is likely to reverse a transaction;<br>
            (b)the sender made a transfer to you by mistake;<br>
            (c)you have provided us with incorrect instructions for the transaction;<br>
            (d)we suspect that a transaction is fraudulent, or is in breach of these terms or Applicable Law;<br>
            (e)we exercise our rights under clause 19.<br>

            You must reimburse us as soon as possible, as set out in clause 12.5. If we reverse a transaction, we will, in our sole discretion, do this at the prevailing FX Exchange Rate applicable at the time of the reversal or at the original FX Exchange Rate applicable to the transaction.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">11.2 Rejected Transactions.<br></span>We may withhold, reject, or delay any funds received, exchanged or sent in the following circumstances:<br>

            (a)no valid exchange rates are available from our correspondent banking partners, liquidity providers, or other financial institutions;<br>
            (b)any relevant anti-money laundering or counter financing of terrorism limits are exceeded;<br>
            (c)you have not complied with your obligations under these terms, the Acceptable Use Policy or under Applicable Law; <br>
            (d)we exercise our rights under clause 19.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">11.3 Failed Transactions.<br></span>11.3We may, in our sole discretion and without liability to you, cancel any FX Conversion or Payment, or delay any FX Conversion or Payment by rolling it over to the next Conversion Date or Payment Date (as applicable), where we need to do so due to reasons outside of our reasonable control, such as where there are insufficient funds.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 11.4 Insufficient funds.<br></span>11.4Where there are insufficient funds for one or more FX Conversions and/or Payments at Settlement Cut-Off, {{ themeData.saasMerNameEn }} reserves the right to make the FX Conversion or Payment at its discretion (for example, on a time basis, converting the earliest booked FX Conversion or Payment up to the value of the funds available to {{ themeData.saasMerNameEn }} at the applicable Conversion Date or Payment Date). The remaining booked FX Conversions will be treated as failed transactions, and will incur the relevant break costs and administration fees.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 11.5 Transfers using correct details.<br></span>

            (a)In each case, you will need to ensure the funds are transferred to us using the correct bank details and any unique transaction reference number details specified by {{ themeData.saasMerNameEn }}. If you do not provide accurate details, then we may not be able to receive the funds and record the amount in the relevant account. In such cases, we will not be liable for any loss you may suffer or incur, although we will use reasonable efforts to investigate and credit or return incorrect and inaccurately referenced payments into or from your account. <br>
            (b)Where the incorrect Payment instructions contained in the Customer Request relates to a prior FX Transaction, the Customer shall be liable for the costs of booking a reversal of the FX Transaction over the {{ themeData.saasMerNameEn }} Portal, if the Customer chooses to cancel a Customer Request as a result of the provision of incorrect Payment instructions. <br>
            (c){{ themeData.saasMerNameEn }} does not guarantee, if a Customer cancels a Customer Request due to incorrect Payment instructions, and submits a new Customer Request for the return of Customer funds, that the amount or currency returned by {{ themeData.saasMerNameEn }} shall equate to the Payment Currency and/or amount.<br>
            (d)Where the Customer provides incorrect FX Transaction instructions (which may include, but is not limited to, the incorrect Settlement Date, invalid currency pairings, and/or incorrect amounts in the Buy Currency or Sell Currency) in the Customer Request over the {{ themeData.saasMerNameEn }} Portal, the Customer agrees to provide {{ themeData.saasMerNameEn }} with a correct Customer Request as soon as practicable, or cancel the Customer Request. Upon cancellation of the Customer Request, the Customer must provide a new Customer Request to {{ themeData.saasMerNameEn }} for the Customer funds which were subject to the incorrect Customer Request, to be returned to the Customer.<br>
            (e){{ themeData.saasMerNameEn }} reserves the right to reject a corrected Customer Request if it is unable to source a valid rate from the relevant correspondent bank or liquidity provider.<br>
            (f)The Customer shall indemnify {{ themeData.saasMerNameEn }} for any and all loss suffered by {{ themeData.saasMerNameEn }} as a result of any failed Payment directly arising from the Customer's failure to adhere to the procedures set out in this Clause.<br>

          </p>
          <p class="detail">
            <span class="detailTitle"> 11.6 Excess funds.<br></span>When you pay into the Correspondent Account, you must give us an accurate and complete instruction in relation to a Payment or FX Conversion. If you do not give us an accurate or complete instruction we may, at our sole discretion:<br>
            (a) return the funds to the sender;<br>
            (b) remit the funds to your linked bank account as notified to us; <br>
            (c) remit the funds to you in any other way, subject to any Applicable Law.<br>
          </p>
        </div>
        <p class="navigationTitle">12.SAFETY AND SECURITY<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">12.1 Keep your {{ themeData.saasMerNameEn }} Account safe.<br></span>In order to use the {{ themeData.saasMerNameEn }} Portal, you (or your Authorised User) must log in using the unique password and any multiple-factor authentication: for example, we may require your Authorised User to authenticate that they are the Authorised User when logging in to the {{ themeData.saasMerNameEn }} Portal, or for certain transactions made using the {{ themeData.saasMerNameEn }} Portal. You must store all log-in information and passwords to access the {{ themeData.saasMerNameEn }} Portal safely and securely at all times and only allow Authorised Users to access the Services.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 12.2 Contact us if you suspect your {{ themeData.saasMerNameEn }} Account may be compromised.<br></span>You must contact us immediately by email to {{ themeData.email }} and change your password if you suspect:<br>

            (a)your {{ themeData.saasMerNameEn }} Account, or access to the {{ themeData.saasMerNameEn }} Portal or other security credentials are stolen, lost, used without your authorisation or otherwise compromised; <br>
            (b)someone else finds out your log-in information and password for your {{ themeData.saasMerNameEn }} Account or User Profile.<br>

            Any undue delay in notifying us may affect the security of your {{ themeData.saasMerNameEn }} Account, or access to the {{ themeData.saasMerNameEn }} Portal and may result in you being responsible for any financial losses.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">12.3 Check the transactions in your accounts regularly.<br></span>All of your activity is displayed in the {{ themeData.saasMerNameEn }} Portal. You must contact us immediately by email to {{ themeData.email }} to report any suspected or actual unauthorised transaction or other security concerns regarding the {{ themeData.saasMerNameEn }} Portal. We may be able to refund money you have lost if the transaction is due to our mistake, fraud, or errors with your transaction (see clause 12.4). If you do not notify us of any unauthorised, or incorrectly initiated or executed transactions immediately, you may lose the right to have the matter corrected or money refunded. You also may not be able to claim a refund if:<br>

            (a)you have breached these terms or any Applicable Law;<br>
            (b)you gave us incorrect instructions for the transaction (in which case, we may reasonably assist you to recover the funds, where possible, but we do not guarantee that this would be successful); <br>
            (c)any losses are not the direct result of our act or omission.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 12.4 Unauthorised transactions.<br></span>We are not liable for unauthorised transactions that are caused outside of the direct control of {{ themeData.saasMerNameEn }}. In particular, but without limitation, we are not liable (and you will be solely liable for all losses):<br>

            (a)if you do not promptly notify us of security issues;<br>
            (b)if we find that you or your Authorised Users, or anyone else acting on your behalf, has acted fraudulently or recklessly (and we will not refund you in any circumstances);<br>
            (c)if you have with intent or gross negligence compromised the security of your {{ themeData.saasMerNameEn }} Account or the {{ themeData.saasMerNameEn }} Portal or failed to comply with your obligations to use them in accordance with these terms, and to keep all log-in information and passwords confidential and secure;<br>
            (d)if you gave us incorrect instructions for the Payment (we may reasonably assist you to recover the funds, where possible, but we do not guarantee that this would be successful); <br>
            (e)in any other circumstance outside of our direct control to the extent permitted by Applicable Law.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 12.5 Reimbursements.<br></span>12.5Where we refund you for an unauthorised transaction but subsequently find that you did not comply with your obligations under this clause 12, you must reimburse us for the value of the unauthorised transaction.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">12.6 Acknowledgements.<br></span>You acknowledge that:<br>

            (a){{ themeData.saasMerNameEn }} will not be responsible for or otherwise guarantee the performance of any transaction you enter into via the {{ themeData.saasMerNameEn }} Portal;<br>
            (b)you must obtain and use any equipment or telecommunications lines and connections that may be necessary for you to use or connect with the {{ themeData.saasMerNameEn }} Portal;<br>
            (c)certain software and equipment you use may not be capable of supporting certain features of the {{ themeData.saasMerNameEn }} Portal; <br>
            (d)it is your responsibility to configure and update your information technology, software and equipment in order to access the Services or the {{ themeData.saasMerNameEn }} Portal.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">12.7 Your liability.<br></span>You will be liable for all costs, charges, claims, damages, fees, disbursements, losses, expenses, fines, and liability suffered or incurred by {{ themeData.saasMerNameEn }} arising out of or in connection with: (i) (where applicable) your incorrect instructions, overpayment, payment error, or other invalid payment you cause; (ii) any error, default, negligence, misconduct, or fraud by you, your employees, directors, officers, or representatives, or anyone acting on your behalf; and (iii) any costs, charges, claims, damages, fees, disbursements, losses, expenses, fines and liability suffered or incurred by {{ themeData.saasMerNameEn }} in respect of a failure by you to comply with these terms, including but not limited to, this clause 12.<br>
          </p>
        </div>
        <p class="navigationTitle">13.FEES & CHARGES<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">13.1 Service Fees.<br></span>You will pay us the Service Fees for the Services, which are set out in the fees table available on {{ themeData.website }} or as otherwise agreed in writing with us. The Service Fees are charged separately depending on the Service you use, for example:<br>

            (a)Collection Services: Service Fees for Collection Services generally involve a set percentage fee on funds and / or flat fee charged on each transaction collected for you  and / or charged in a certain period such as daily, weekly, monthly, quarterly or annually.<br>
            (b)Payment Services: Service Fees for Payment Services generally involve a fee depending on whether you are paying using local channels or SWIFT payment channels and / or flat fee charged on each transaction and / or charged in a certain period such as daily, weekly, monthly, quarterly or annually.<br>
            (c)FX Conversion Services. Service Fees for FX Conversion Services generally involve a Customer Margin charged on top of the FX Base Rate and / or flat fee charged on each transaction and / or charged in a certain period such as daily, weekly, monthly, quarterly or annually.<br>
            (d){{ themeData.saasMerNameEn }} Portal Services. Service Fees that may be charged on a timely basis, for example, monthly, for the usage of {{ themeData.saasMerNameEn }} Portal Services for Merchants to initiate systematic or electronic commands such as recharge, cash withdrawal, query, reconciliation, personalized settings, etc, to {{ themeData.saasMerNameEn }}.<br>

          </p>
          <p class="detail">
            <span class="detailTitle">13.2 Changes to Service Fees.<br></span>Unless we agree otherwise in writing, we may revise the Service Fees at any time and the revisions will take effect on the date we notify you that they will apply.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">13.3 Tax.<br></span>All amounts payable to us under these terms:<br>

            (a)are exclusive of all taxes and similar fees now in force or enacted in the future, all of which you will be responsible for and will pay in full;<br>
            (b)will be paid in full without any set-off, counterclaim, deduction or withholding unless prohibited by Applicable Law. If you are obliged by Applicable Law to deduct withholding tax from any payment: (i) you will promptly notify {{ themeData.saasMerNameEn }} of the requirement; (ii) the Parties will make all necessary filings in order to ensure the provisions of any applicable tax treaty applies to the payment; (iii) you will pay to {{ themeData.saasMerNameEn }} such additional amount as will result in the receipt by {{ themeData.saasMerNameEn }} of the full amount which would otherwise have been receivable had no withholding or deduction been payable; (iv) you will pay to the relevant authorities the full amount required to be deducted or withheld when due; and (v) you will promptly forward to {{ themeData.saasMerNameEn }} an official receipt (or a certified copy), or other documentation reasonably acceptable to {{ themeData.saasMerNameEn }}, evidencing payment to such authorities.<br>

          </p>
          <p class="detail">
            <span class="detailTitle">13.4 Obligation to pay amounts owing.<br></span>You must immediately pay any amount owing to us, including as a result of any reversals, returns, fees, charges or other transactions, and we may suspend your use of the Services and access to any associated accounts until we receive such payment.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">13.5 Authority to deduct amounts you owe us.<br></span>We may deduct any amounts that you owe us from time to time from the funds we collect or receive as part of the Services or from any amount provided as security pursuant to clause 13.6 of these terms. If you do not have enough funds to pay the amount you owe us, we may, at your cost, take reasonable steps to recover this amount (such as taking legal action or using debt collection services).<br>
          </p>
          <p class="detail">
            <span class="detailTitle">13.6 Security.<br></span>We may, at any time, require that you procure, within 30 days (or such longer period as we may determine is reasonable) after receiving our written request, a guarantee, indemnity or other security (including the replacement of any existing security) in such form and over such assets as we may reasonably require to secure to our reasonable satisfaction the performance of your obligations (including contingent or potential obligations) from time to time under these terms.<br>

          </p>
          <p class="detail">
            <span class="detailTitle">13.7 Method of payments of Service Fees:<br></span>

            (a)For Fees occurred during each single transaction:  When each transaction is successful confirmed by {{ themeData.saasMerNameEn }}, {{ themeData.saasMerNameEn }} will directly deduct the equivalent fee amount in fee currency from the prefund account balance of the Customer in the actual debit currency of the transaction based on the foreign exchange market’s exchange rate at the time of deduction (If the actual fee currency and the debit currency are the same, then no foreign exchange will occur). When the balance is insufficient, the Customer is obliged to make up the amount in a timely manner and is responsible for any problems caused by the insufficient amount.<br>
            (b)For Fixed Fees:  {{ themeData.saasMerNameEn }} takes the initiative to deduct any fixed fees amount in fee currency from the prefund account balance of the Customer in the actual debit currency of the transaction based on the foreign exchange market’s exchange rate at the time of deduction (If the actual fee currency and the debit currency are the same, then no foreign exchange will occur) on the first [1st] day of each month. When the balance is insufficient, the Customer is obliged to make up the amount in a timely manner and is responsible for any problems caused by the insufficient amount.<br>
            (c)For Setup & other 1-time Fees:  {{ themeData.saasMerNameEn }} will issue the Customer with an invoice for the Setup & other 1-time Fees, to be paid in full prior to the continuing of any Service on the first [1st] day of each month. Customer should hereby accept the invoice and honor the payment immediately to the beneficiary bank account as designated in the invoice.<br>
          </p>
        </div>
        <p class="navigationTitle">14.INTELLECTUAL PROPERTY RIGHTS<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">14.1 Licence. <br></span>We grant you a personal, revocable, non-transferable, non-sublicensable and non- exclusive licence to access and use the {{ themeData.saasMerNameEn }} Portal via any supported web browser for the management of our Services, provided you comply with these terms.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">14.2 Ownership.<br></span>We own all Intellectual Property Rights in and to the {{ themeData.saasMerNameEn }} Portal and its proprietary technology, including its software (in source and object forms), algorithms, user interface designs, architecture, and documentation (both printed and electronic), and network designs, and including any modifications, improvements, and derivative work thereof. These terms do not transfer from {{ themeData.saasMerNameEn }} to you any ownership rights in the {{ themeData.saasMerNameEn }} Portal, and/or its proprietary technology.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">14.3 Restriction on use.<br></span>You do not have any right to our Services, including the {{ themeData.saasMerNameEn }} Portal, other than the right to use them in accordance with the licence granted in clause 14.1. Except as allowed by Applicable Law or by {{ themeData.saasMerNameEn }}, you cannot use, distribute, reproduce, modify, copy, adapt, translate, create derivative works from, transfer, loan, rent, sublicense, sell, frame or otherwise re-publish or redistribute, publicly perform or publicly display any part of our {{ themeData.saasMerNameEn }} Portal, Services, or included software. You will not allow any unauthorised person to access or use the {{ themeData.saasMerNameEn }} Portal, or trade on the {{ themeData.saasMerNameEn }} Portal for speculative purposes. Except as expressly permitted by Applicable Law (but not otherwise), you also cannot reverse engineer, decompile, disassemble or attempt to extract the source code of the {{ themeData.saasMerNameEn }} Portal or software.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">14.4 Using your Data.<br></span>You grant {{ themeData.saasMerNameEn }} a royalty-free, non-exclusive, irrevocable, transferable and sub-licensable licence to any Group Company, third party supplier or outsourcer, to use your Customer Data and Payment Data, for the purposes of operating the {{ themeData.saasMerNameEn }} Portal, providing the Services, and fulfilling {{ themeData.saasMerNameEn }}’s rights and discharging its obligations under these terms. You are solely responsible for the quality, accuracy, and completeness of any Customer Data and Payment Data transmitted via the {{ themeData.saasMerNameEn }} Portal.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">14.5 Other people’s rights.<br></span>You will not use our Services, including the {{ themeData.saasMerNameEn }} Portal, in any way that infringes or violates our or anyone else’s copyright, trade mark or other intellectual property rights or otherwise breaks any Applicable Law. The {{ themeData.saasMerNameEn }} Portal may display content that we do not own, and we are not responsible for. You may not use content from any of our Services, including the {{ themeData.saasMerNameEn }} Portal, unless you get permission beforehand from us or the owner of the content, or you are permitted by law.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">14.6 Promotion.<br></span>You agree that we may include your business information in our general promotional materials for our Services. You may at any time request in writing that we stop using your business information for these purposes.<br>
          </p>
        </div>
        <p class="navigationTitle">15.CONFIDENTIALITY, PRIVACY AND USE OF DATA<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">15.1 Confidential Information.<br></span>You acknowledge that you may have access to Confidential Information belonging to us and that you must keep such information confidential in accordance with the terms of these terms.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.2 Confidentiality Undertakings.<br></span>You agree that you:<br>

            (a)will not disclose any of our Confidential Information to any third party except as required:
            (i)by law or any authority of competent jurisdiction; (ii) to your attorneys, accountants and other advisors as reasonably necessary; or (iii) for the purposes of defending yourself in relation to actual or threatened proceedings, provided that in respect of (i) and (iii) above, you will give us reasonable notice in advance of such required disclosure, together with such details as we may request (where notice to us is permissible under Applicable Laws); <br>
            (b)will take reasonable precautions to protect the confidentiality of such information, at least as stringent as those taken to protect your own Confidential Information.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.3 Disclosures to employees and agents.<br></span>In addition, you may only reveal our Confidential Information to your agents, representatives and employees who have a ‘need to know’ such information in connection with these terms, and who are informed of the confidential nature of such Confidential Information and agree to act in accordance with the terms and conditions of this clause 15. You will remain liable for any disclosure of Confidential Information by your agents, representatives and employees as if you had made such disclosure.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.4 Privacy and Personal Data.<br></span>The protection of Personal Data is very important to us. In addition to these terms, our Privacy Policy explains how and for what purposes we collect, use, retain, disclose, and safeguard the Personal Data you provide to us. You agree to review the terms of our Privacy Policy, which we update from time to time.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">Purposes.</span>You agree to share Personal Data with {{ themeData.saasMerNameEn }} and authorise {{ themeData.saasMerNameEn }} to collect, use, store or otherwise process such Personal Data for the following ‘Agreed Purposes’, to enable us to:<br>

            <a-badge status="default" />assist in providing information to you about a product or service;<br>
            <a-badge status="default" />consider your request for a product or service;<br>
            <a-badge status="default" />enable us to provide a product or service;<br>
            <a-badge status="default" />manage {{ themeData.saasMerNameEn }}’s relationship with the Customer;<br>
            <a-badge status="default" />manage accounts and perform other administrative and operational tasks (including risk management, systems development and testing, credit scoring, staff training, collecting debts and market or customer satisfaction research);<br>
            <a-badge status="default" />design new products and services or improve existing products and services provided by us;<br>
            <a-badge status="default" />general and specific communication between us and you;<br>
            <a-badge status="default" />consider any concerns or complaints you raise against us, to manage any legal action involving {{ themeData.saasMerNameEn }}, or both;<br>
            <a-badge status="default" />carry out any enforcement activities including to collect any money due from you;<br>
            <a-badge status="default" />identify you or establish your tax status under any local or foreign legislation, regulation or treaty or pursuant to an agreement with any tax authority;<br>
            <a-badge status="default" />identify, prevent or investigate any fraud, unlawful activity or misconduct (or suspected fraud, unlawful activity or misconduct);<br>
            <a-badge status="default" />comply with all Applicable Law, including conducting anti-money laundering, financial crime and other screening checks;<br>
            <a-badge status="default" />verify any information and records relating to you;<br>
            <a-badge status="default" />comply with these terms and the Privacy Policy;<br>
            <a-badge status="default" />enable an actual or proposed assignee(s) of all or any part of our business and/or assets to evaluate the transaction intended to be the subject of the assignment and to enable the actual assignee(s) to use the Personal Data provided by you in the operation of the business; and<br>
            <a-badge status="default" />for any other purpose set out in our Privacy Policy.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.5 Data Subjects.<br></span>The categories of Data Subjects who we may collect Personal Data about may include the following where they are a natural person: the Customer, the directors and ultimate beneficial owner(s) of the Customer, your customers, employees and contractors, payers and payees. You may share with {{ themeData.saasMerNameEn }} some or all of the following types of Personal Data regarding Data Subjects:<br>

            <a-badge status="default" />full name;<br>
            <a-badge status="default" />email address;<br>
            <a-badge status="default" />phone number and other contact information;<br>
            <a-badge status="default" />date of birth;<br>
            <a-badge status="default" />nationality;<br>
            <a-badge status="default" />public information about the data subject;<br>
            <a-badge status="default" />other relevant verification or due diligence documentation as required under these terms; and<br>
            <a-badge status="default" />any other data that is necessary or relevant to carry out the Agreed Purposes.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.6 Compliance.<br></span>Neither party will knowingly perform its obligations under these terms in a way that causes the other party to breach applicable Data Protection Legislation. Neither party will be responsible for any consequences resulting from the other party’s failure to comply with applicable Data Protection Legislation in relation to Personal Data that it shares.<br>
            (a)You undertake that each and every individual payment information completed through {{ themeData.saasMerNameEn }} is true and valid, with a real and legal transaction background, to ensure the legal compliance of source and flow of settlement funds. You shall submit to {{ themeData.saasMerNameEn }} details of each transaction that can prove the authenticity of the transaction in accordance with the requirements of {{ themeData.saasMerNameEn }}, including but not limited to information of both parties to the transaction, product names, quantity of the transaction product, transaction time, transaction amount, payment information, logistics information, consumer signature receipt, agreement and other information. You are solely liable for all responsibilities arising from your forged transaction details and cooperation materials.<br>
            (b)You shall be obliged to save all transaction information and data files generated through {{ themeData.saasMerNameEn }} and keep all due diligence files within the validity period of this agreement for at least 5 years after the termination of this agreement (if the laws and regulations stipulate a longer period, the laws and regulations shall prevail). You need to ensure that when notified by us or requested within the record keeping period set out in the Hong Kong Anti-Money Laundering Ordinance, you provide us as soon as reasonably any document, data or information.<br>
            (c)We will conduct spot checks from time to time, and you should provide due diligence information or documents as soon as we request it.<br>
            (d)We have reasonable grounds to suspect your reliability and we may take immediate steps to review your ability to perform due diligence.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.7 Undertakings.<br></span>To the extent either you or {{ themeData.saasMerNameEn }} processes Personal Data, each party will:<br>

            (a)process the personal data fairly and lawfully and ensure it has legitimate grounds under Data Protection Legislation for such processing;<br>
            (b)obtain and maintain all appropriate registrations required under Data Protection Legislation to allow it to use the personal data in accordance with these terms;<br>
            (c)ensure appropriate technical and organisational security measures are in place to protect Personal Data under its control;<br>
            (d)ensure that all personnel who have access to and/or process the Personal Data are obliged to keep it confidential;<br>
            (e)transfer Personal Data between jurisdictions only where it has taken appropriate measures to make such a transfer lawful under Data Protection Legislation;<br>
            (f)notify the other party promptly if it receives any request, complaint or other communication from a data subject or a Regulatory Body that is addressed to or intended for the other party; <br>
            (g)notify the other party promptly if it becomes aware of a Personal Data breach that is directly relevant to the other party.<br>

          </p>
          <p class="detail">
            <span class="detailTitle">15.8 Data Security.<br></span>You will ensure that any Personal Data that is transferred to {{ themeData.saasMerNameEn }} is transferred by a method and means that is secure and compliant with Data Protection Legislation in addition to any other reasonable information security requirements specified by us.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.9 Cooperation.<br></span>Each party will be responsible for responding to requests from supervisory authorities or individuals exercising their rights under applicable Data Protection Legislation. Each party will provide the other with reasonable cooperation and assistance to comply with all obligations imposed on them by the Data Protection Legislation and any relevant Applicable Law, including: (i) dealing with and responding to any communications from Data Subjects; (ii) dealing with, mitigating and responding to any breach of personal data; and (iii) with respect to security, impact assessments and consultations with supervisory authorities or Regulatory Bodies.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.10 Data Disposal.<br></span>On termination of these terms, or once processing of any Personal Data is no longer necessary to carry out the Agreed Purposes, each party will securely dispose of any such Personal Data in its possession required by Applicable Law.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.11 Data Security.<br></span>We will take all steps required under Data Protection Legislation to ensure appropriate information security safeguards are put in place regarding such disclosure.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.12 Personal data you provide about someone else.<br></span>If you give us Personal Data about someone else, including (but not limited to) your Authorised Users, your director(s) and ultimate beneficial owner(s), your customers, employees and contractors, payers and payees, you undertake that we may rely on you to obtain such other persons' consent for disclosing their Personal Data to us which we will use in accordance with the terms of these terms. You are required to show them information about these provisions so that they may understand the manner in which their Personal Data may be collected, used and disclosed by {{ themeData.saasMerNameEn }} in connection with your dealings with us and our use of their Personal Data including our use of Personal Data for Direct Marketing purposes and their consent to our use of their Personal Data provided by you on their behalf.<br>
          </p>
        </div>
        <p class="navigationTitle">16.DISCLOSURE OF INFORMATION AND USE OF PERSONAL DATA FOR DIRECT MARKETING<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">16.1 We may disclose your information to others.<br></span>{{ themeData.saasMerNameEn }} may disclose your Customer Data, Payment Data and Personal Data, including any Confidential Information, to:<br>

            <a-badge status="default" />{{ themeData.saasMerNameEn }} may disclose your Customer Data, Payment Data and Personal Data, including any Confidential Information, to:<br>
            <a-badge status="default" />any agent, contractor or service provider that we engage or propose to engage to carry out or assist us with our functions and activities who is under a duty of confidentiality to keep such information confidential;<br>
            <a-badge status="default" />participants in the payments system including card schemes, financial institutions and payments organisations such as Society for Worldwide Interbank Financial Telecommunication;<br>
            <a-badge status="default" />any person to whom {{ themeData.saasMerNameEn }} may enter into any assignment, fundraising, share purchase or other commercial agreement who is under a duty of confidentiality to keep such information confidential;<br>
            <a-badge status="default" />any person to whom information is permitted or required (or expected) to be disclosed by any applicable law or regulation or pursuant to any order of court;<br>
            <a-badge status="default" />any referee or representative or yours (including any professional advisor, broker, introducer, attorney or executor);<br>
            <a-badge status="default" />any third party provider of collateral, security or credit support (if any);<br>
            <a-badge status="default" />credit reference agencies, and, in the event of default, to debt collection agencies;<br>
            <a-badge status="default" />any of your agents you have authorised or who is authorised under law such as an administrator or trustee in bankruptcy or your legal representative; <br>
            <a-badge status="default" />any actual or proposed assignee of all or any part of our business and/or assets and/or interests of {{ themeData.saasMerNameEn }}.<br>
          </p>
          <!--          <p class="detail">-->
          <!--            <span class="detailTitle">16.2 Use of Personal Data in Direct Marketing<br/></span>We may use the Personal Data provided by you in direct marketing and we require consent (or an indication of no objection) from the Data Subject for that purpose. In this connection, please note that:<br/>-->

          <!--            <Icon type="record"></Icon>the name, contact details, products and other service information, transaction pattern and behavior, financial background and demographic data of the Data Subjects held by us from time to time may be used by us in direct marketing;<br/>-->
          <!--            <Icon type="record"></Icon>the following classes of services, products and subjects may be marketed:<br/>-->
          <!--            <Icon type="record"></Icon>o financial, insurance, credit card, banking and related services and products;<br/>-->
          <!--            <Icon type="record"></Icon>o reward, loyalty, co-branding or privileges programmes and related services and products;<br/>-->
          <!--            <Icon type="record"></Icon>o services and products offered by our co-branding partners (the names of such co- branding partners will be provided during the application for the relevant services and products, as the case may be); <br/>-->
          <!--            <Icon type="record"></Icon>o donations and contributions for charitable and/or non-profit making purposes;<br/>-->
          <!--            <Icon type="record"></Icon>the above services, products and subjects may be provided by us or (in the case of donations and contributions) solicited by us and/or any of the following:<br/>-->
          <!--            <Icon type="record"></Icon>o any branch, subsidiary, holding company, associated company, affiliate or related entity of {{themeData.saasMerNameEn }};<br/>-->
          <!--            <Icon type="record"></Icon>o third party financial institutions, insurers, credit card companies, securities and investment services providers;<br/>-->
          <!--            <Icon type="record"></Icon>o third party reward, loyalty, co-branding or privileges programme providers;<br/>-->
          <!--            <Icon type="record"></Icon>o co-branding partners of {{themeData.saasMerNameEn }} or any branch, subsidiary, holding company, associated company, affiliate or related entity of {{themeData.saasMerNameEn }} (the names of such co- branding partners will be provided during the application of the relevant services and products, as the case may be); <br/>-->
          <!--            <Icon type="record"></Icon>o charitable or non-profit making organisations.<br/>-->
          <!--            If you (or any persons for whom you provide the Personal Data to us) do not wish us to use or provide to other persons your/their Personal Data for use in direct marketing as described above, you or such persons (as the case may be) may exercise the right to opt-out by notifying us.<br/>-->
          <!--          </p>-->
        </div>
        <p class="navigationTitle">17.LIMITATION OF LIABILITY<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">17.1 LIMITATION OF LIABILITY<br></span>Nothing in these terms excludes or limits either party’s liability where it would be unlawful to do so. This includes a party’s liability for:<br>

            (a)death or personal injury resulting from its negligence;<br>
            (b)fraud or fraudulent misrepresentation;<br>
            (c)all sums properly due and payable under these terms.<br>

          </p>
          <p class="detail">
            <span class="detailTitle">17.2 Direct Losses.<br></span>Direct Losses will be recoverable under these terms. However, save as otherwise stated in these terms, neither party will be liable for any Indirect or Consequential Losses.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">17.3 Exclusions. <br></span>We will not be liable to you for:<br>

            (a)any loss outside of our direct control that arises from the negligence, fraud or wilful misconduct or the insolvency of any third-party correspondent bank, liquidity provider, or other financial institution who is part of the payment network used to provide the Services;<br>
            (b)the non-execution, or defective execution, of a Payment if any information you provide in your instructions is incorrect; <br>
            (c)errors, mistakes, or non-performance arising from the payee/beneficiary bank if the payee/beneficiary bank fails to process the payment correctly.<br>

            In the case of (b) or (c), we will make reasonable efforts to recover the funds involved in the payment. You will be responsible for all costs incurred by us as part of any such recovery attempt, even if we are unable to successfully recover the funds.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">17.4 Aggregate Liability.<br></span>Save as set out in clause 17.5, {{ themeData.saasMerNameEn }}’s total aggregate liability to you for all Actions arising out of or in connection with these terms will not exceed the total amount of Service Fees paid by you to {{ themeData.saasMerNameEn }} during the one (1) year period prior to the date the liability first arose.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">17.5 Liability with respect to FX Conversion.<br></span>To the fullest extent permitted by Applicable Law, {{ themeData.saasMerNameEn }}’s maximum liability for a FX Conversion, whether arising in contract, tort or otherwise, will in no circumstances exceed an amount equal to the currency sold by {{ themeData.saasMerNameEn }} under that FX Conversion.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">17.6 Equitable Relief.<br></span>Damages alone may not be an adequate remedy for breach and accordingly either party will be entitled to seek the remedies of injunction, specific performance or other equitable relief for any threatened or actual breach.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">17.7 Mitigation.<br></span>Each party agrees to use reasonable endeavours to mitigate any loss in respect of which it is indemnified under these terms.<br>

          </p>
        </div>
        <p class="navigationTitle">18.INDEMNITY<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">18.1 Customer’s indemnity.<br></span>You will indemnify us on demand against all losses, damages, costs (including reasonable legal fees) and expenses incurred or suffered by us in connection with or as a result of:<br>

            (a)your breach of these terms, failure to comply with Applicable Law, failure to comply with the Acceptable Use Policy, or your use or misuse of our Services;<br>
            (b)a third party alleging that our use of the Customer Data as permitted by these terms infringes any Intellectual Property Rights;<br>
            (c)any of your Payments or FX Conversions, including us acting on any of your instructions which we reasonably believe to have been made by you or your Authorised User; <br>
            (d)the closure or cancellation of all or any part of a FX Conversion or Payment before its Conversion Date or Payment Date, including if we have to do so due to you failing to provide funds for the FX Conversion or Payment.<br>

          </p>
          <p class="detail">
            <span class="detailTitle">18.2 Third party Claim.<br></span>This clause 18.2 and clauses 18.3 and 18.4 apply in respect of the obligations in this clause 18 and clause 22. If a party becomes aware of any claim or proceeding which might lead to a claim under this clause 18 or clause 22 (each, a ‘Third party Claim’), the party will promptly notify the other party in writing and, subject to the other party complying at all times with clause 18.3, the first party will:<br>

            (a)allow the other party to control the defence of such Third party Claim; <br>
            (b)at the request and expense of the other party, provide reasonable assistance for the other party to defend any such Third party Claim.<br>

          </p>
          <p class="detail">
            <span class="detailTitle">18.3 Defence.<br></span>If the other party assumes the defence of a Third party Claim, the other party will:<br>

            (a)promptly notify the first party that it has assumed such defence;<br>
            (b)at all times have regard for the interests and reputation of the first party and comply with all applicable court rules in the defence of such Third party Claim;<br>
            (c)consult and keep the first party informed, in relation to any negotiations, settlement or litigation; <br>
            (d)not, without the prior written consent of the first party, enter into any settlement or compromise of the Third party Claim that involves a remedy other than the payment of money.<br>

          </p>
          <p class="detail">
            <span class="detailTitle">18.4 Indemnification.<br></span>If the other party does not assume the defence of a Third party Claim in accordance with clause 18.3 within 14 days of being notified in writing of that Third party Claim, the first party, or a Person designated by the first party, may defend the Third party Claim in such manner as it may deem appropriate, and the other party will indemnify the first party on demand in relation to all costs, claims and liabilities incurred by the first party in relation to such Third party Claim.<br>

          </p>
          <!--          <p class="detail">-->
          <!--            <span class="detailTitle">18.5 Break costs.<br/></span>Without limiting any other provision of these terms, if we have funded or arranged to fund all or part of any FX Conversion or Payment on your instruction, and you have cancelled or failed to fully fund that FX Conversion or Payment, you will indemnify us on demand against any losses, damages, costs (including reasonable legal fees) and expenses incurred or suffered by us as a result of the investment, deposit or other deployment of that funding or the unwinding of any arrangement for that funding.<br/>-->
          <!--          </p>-->
          <p class="detail">
            <span class="detailTitle">18.5 Conditions to Indemnification<br></span>The obligations set forth in this clause 18 shall apply only if the indemnified Party notifies the indemnifying Party in writing of a claim within thirty (30) days of learning of or receiving the same; the indemnified Party provides the indemnifying Party with reasonable assistance requested by the indemnifying Party, at the indemnifying Party's expense, for the defence and settlement, if applicable, of any Claim; and the indemnified Party provides the indemnifying Party with the exclusive right to control and the authority to settle any Claim.<br>
          </p>
          <!--          <p class="detail">-->
          <!--            <span class="detailTitle">18.7 Sole and Exclusive Remedies.<br/></span>The rights and obligations in this clause 18 are the indemnifying Party's sole and exclusive obligations, and the indemnified Party's sole and exclusive remedies with respect to any such claims.<br/>-->
          <!--          </p>-->
        </div>
        <p class="navigationTitle">19.TERMINATION AND SUSPENSION<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">19.1 Closing your {{ themeData.saasMerNameEn }} Account.<br></span>You can close your {{ themeData.saasMerNameEn }} Account by stopping the use of the Services and terminating these terms at any time by giving us 30 calendar days prior written notice.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">19.2 {{ themeData.saasMerNameEn }}’s rights to suspend or terminate.<br></span>We may terminate these terms (or any Additional Terms) at any time by giving you 30 calendar days prior written notice. In addition, we may without notice immediately suspend or terminate all or any part of these terms, any Additional Terms or any of the Services, or block any transactions, if:<br>
            (a)we suspect criminal activity in connection with your {{ themeData.saasMerNameEn }} Account, that your {{ themeData.saasMerNameEn }} Account is being used fraudulently, or we reasonably believe you have fraudulently requested a refund for an unauthorised transaction;<br>
            (b)we reasonably believe you are in breach of Applicable Law or about to breach an Applicable Law;<br>
            (b)we reasonably believe you are in breach of Applicable Law or about to breach an Applicable Law;<br>
            (d)you have breached any provision in these terms or breached any applicable limits or restrictions in the Acceptable Use Policy;<br>
            (e)you have given us false or inaccurate information, or we have been unable to verify any information you have provided;<br>
            (f)you notify us, or we suspect or identify, any suspected or actual unauthorised transactions;<br>
            (g)you have been placed on any national or international restricted or prohibited lists, which prevents us from doing business with you;<br>
            (h)you have been abusive to anyone at {{ themeData.saasMerNameEn }};<br>
            (i)you have not accepted changes to these terms;<br>
            (j)we suspend or stop all, or part of, the Services, including for technical or security reasons;<br>
            (k)we have reasonable concerns about your {{ themeData.saasMerNameEn }} Account, including the use of the Services;<br>
            (l)you haven’t paid or repaid any amounts owing to us by the date due;<br>
            (m)one of our banking partners, or other service provider necessary to provide the Services, requires us to terminate these terms; <br>
            (n)you do not use your {{ themeData.saasMerNameEn }} Account for 12 months and it becomes inactive.<br>
            (o){{ themeData.saasMerNameEn }} may suspend or terminate the Customer's access to the Merchant Services Platform with one days prior notice to the Customer, if it believes, in its sole discretion, that the Customer is utilizing the MSP for any illegal, disruptive or unauthorised purpose. In addition, {{ themeData.saasMerNameEn }} shall reserve the right to discontinue Services if any of the following circumstances occurs:(i)full or partial Merchant Services Platform failure, including failure of the technology constituting the Merchant Services Platform;(ii)a breach in the security of the MSP;(iii)a material breach by the Customer of its payment obligations under this Agreement; or(iv)in order to comply with applicable laws.<br>
            (p)Any actions taken by {{ themeData.saasMerNameEn }} pursuant to this clause 19.2 shall continue for such time as {{ themeData.saasMerNameEn }} shall determines, in its sole discretion, to be necessary.<br>
          </p>
          <!--          <p class="detail">-->
          <!--            <span class="detailTitle">19.3 Blocking transactions.<br/></span>We may also block any transaction if your instructions are unclear, incomplete or contain an easily identifiable error.<br/>-->
          <!--          </p>-->
          <p class="detail">
            <span class="detailTitle">19.3 We will give you notice of suspension where possible.<br></span>We will give you notice of any suspension and the reasons for such suspension as soon as we can, either before the suspension is put in place, or immediately after, unless it would compromise our reasonable security measures or otherwise be unlawful. We will lift the suspension as soon as practicable after the reasons for the suspension have ceased to exist.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">19.4 Immediate termination.<br></span> Without affecting any other right or remedy available to it, either party may terminate these terms or any Additional Terms immediately on written notice to the other party and without liability to the other party:<br>

            (a)if the other party:<br>
            <span class="use-two-title">(i)commits a material breach of these terms (or the Additional Terms), and, in the case of a breach capable of remedy, fails to remedy such breach within 30 days after receiving formal written notice to do so; <br></span>
            <span class="use-two-title">(ii)commits a series of breaches of these terms (or the Additional Terms) which when taken together have the impact or effect of, or otherwise amount to a material breach; <br></span>
            <span class="use-two-title">(iii)becomes subject to an Insolvency Event;<br></span>
            (b)if the party reasonably determines that it has become unlawful to perform its obligations under these terms; or in the event of a Force Majeure Event that has the effect of preventing the other party from performing any of its obligations under these terms for a period exceeding 1 month.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">19.5 Repayment upon termination.<br></span>On termination you will need to immediately repay any money you owe us. In the circumstances set out in clauses 19.2 or 19.5, we may charge you for any costs, expenses and losses we incur (including costs due to foreign exchange difference or any action we may take to cover or reduce the exposure). Once all amounts owing to us have been repaid, any excess amount held by us in respect of the Services will made available to you.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">19.6 Effect of termination.<br></span>On termination of these terms or any Additional Terms:<br>
            (a)you will not be able to use the {{ themeData.saasMerNameEn }} Portal (or the Services under those Additional Terms) and all rights granted to you under these terms or the Additional Terms will cease;<br>
            (b)you must immediately return or delete copies of any documentation, notes and other materials comprising or regarding the {{ themeData.saasMerNameEn }} Portal (or the Services under those Additional Terms);<br>
            (c)all of your payment obligations under these terms (or under the Additional Terms) for Services provided through to the effective date of termination will immediately become due and payable;<br>
            (d)each party will return or delete all Confidential Information of the other party in its possession within 30 days of the termination of these terms or the Additional Terms, and will not make or retain any copies of such Confidential Information except as necessary to comply with Applicable Law; <br>
            (e)any provision that expressly or by implication has effect after termination will continue in full force and effect.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">19.7 Additional Grounds for Termination<br></span>{{ themeData.saasMerNameEn }} may suspend the Services and terminate this Agreement at any time without prior notice if a financial regulator, regulatory agency, or law enforcement agency posts a warning with regard to the Customer or issues a notice to {{ themeData.saasMerNameEn }} requiring it to discontinue the Services; any governmental, regulatory, or judicial authority directs {{ themeData.saasMerNameEn }} to suspend or terminate this Agreement; the Customer is placed on the restricted or prohibited lists issued by the United Nations or US Government or such other international agencies such as the FATF or HKCED or as per the laws or regulations of countries in which {{ themeData.saasMerNameEn }} operates which prohibit {{ themeData.saasMerNameEn }} from doing any business with the Customer; a banking partner of {{ themeData.saasMerNameEn }} requests that {{ themeData.saasMerNameEn }} terminate this Agreement; or {{ themeData.saasMerNameEn }} believes, in its sole discretion, that the Customer is engaged in false transactions, fraud, money laundering, prostitution, drug trafficking, terrorist financing or any other activity in breach of applicable laws or regulations in such jurisdictions where {{ themeData.saasMerNameEn }} operates its business.<br>
          </p>
        </div>
        <p class="navigationTitle">20.THIRD PARTY SERVICE PROVIDERS<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">20.1 Acknowledgements.<br></span>You acknowledge and agree that:<br>

            (a)we may work with third parties, including other {{ themeData.saasMerNameEn }} Group Companies, to provide the Services;<br>
            (b)if you receive information from us regarding, or provided by, such third parties, you will not rely on such information and will not make any claims against us or the third party in relation to such information. You agree that you bear all risk and responsibility if you choose to rely on such information in contravention of this clause.<br>
            (c){{ themeData.saasMerNameEn }} shall not be responsible for, or otherwise guarantee, the performance of any transaction entered into by the Customer via the {{ themeData.saasMerNameEn }} Portal;<br>
            (d)certain software and equipment used by the Customer may not be capable of supporting certain features of the {{ themeData.saasMerNameEn }} Portal;<br>
            (e)the Customer shall be liable for all costs, charges, claims, damages, fees disbursements, losses, expenses, fines, and liability incurred by {{ themeData.saasMerNameEn }} arising out of:  (i)(where applicable) incorrect instructions by the Customer, over payment, payment error, or other invalid payment caused by the Customer;  (ii)any error, or, default, negligence, misconduct, or fraud by the Customer, employees, directors, officers, representatives of the Customer, or anyone acting on behalf of the Customer; and  (iii)any costs, charges, claims, damages, fees, disbursements, losses, expenses, fines and liability incurred by {{ themeData.saasMerNameEn }} in respect of a failure by the Customer to comply with terms of the Agreement, and this Services Schedule.<br>
            (f){{ themeData.saasMerNameEn }} shall not be liable for any loss arising from any Exchange Rate fluctuation in respect of the Services as a result of any delay, cancellation, or failure of that FX Transaction. <br>
            (g){{ themeData.saasMerNameEn }} shall be entitled to withdraw, withhold, or suspend the provision of the Service to the Customer at any time.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">20.2 Services provided by {{ themeData.saasMerNameEn }}.<br></span>If we use a third party to provide the Services, you acknowledge that the Services are provided by {{ themeData.saasMerNameEn }} to you and not by the third party, unless any Additional Terms for the use of a Service or feature of a Service specifically provide otherwise in writing.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">20.3 Compliance with Data Protection Legislation.<br></span>If we use a third party to provide the Services, we will ensure that the third party complies with Data Protection Legislation relating to the processing of Personal Data pursuant to these terms.<br>
          </p>
        </div>
        <p class="navigationTitle">21.REPRESENTATIONS AND WARRANTIES<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">21.1 Customer warranties.<br></span>You represent and warrant to us that you:<br>
            (a)will comply with these terms and all Applicable Law regarding your use of the Services, the {{ themeData.saasMerNameEn }} Portal and Customer Data;<br>
            (b)have the right, power and authority to enter into these terms and to perform all of your obligations under it;<br>
            (c)have the valid right to grant to us the rights as defined in these terms without violating any Applicable Law or the proprietary rights of any third party;<br>
            (d)will provide us with accurate, up-to-date and complete customer due diligence information and data at all times, pursuant to clause 4 of these terms;<br>
            (e)will pay all sums to us free and clear of any claims, encumbrances or any other interest of any third person; <br>
            (f)will use the Services and the {{ themeData.saasMerNameEn }} Portal only for lawful purposes.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">21.2 Customer conduct.<br></span>You are solely responsible for any use of the Services, and other features of the {{ themeData.saasMerNameEn }} Portal, including the content of any data or transmissions you execute through {{ themeData.saasMerNameEn }} Portal yourself, or by any Authorised User you permit to access {{ themeData.saasMerNameEn }} Portal. You will use all reasonable efforts to ensure that no unauthorised person will or could access your {{ themeData.saasMerNameEn }} Account or other features of the {{ themeData.saasMerNameEn }} Portal. You will not interfere with, disrupt, or cause any damage to other users of the {{ themeData.saasMerNameEn }} Portal or Services.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">21.3 You must not misuse the {{ themeData.saasMerNameEn }} Portal.<br></span>You cannot:<br>

            (a)access the {{ themeData.saasMerNameEn }} Portal using a method other than the {{ themeData.saasMerNameEn }} APIs or MSP, for example you cannot use automated means (including harvesting bots, robots, spiders, or scrapers) without our permission;<br>
            (b)do anything that may disrupt, disable, overburden, or damage the {{ themeData.saasMerNameEn }} Portal, such as a denial of service attack; <br>
            (c)cause viruses or other malicious code to interfere with the use of the {{ themeData.saasMerNameEn }} Portal.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">21.4 Disclaimer.<br></span>Except as expressly stated in these terms, all warranties, conditions and terms, whether express or implied by statute, common law or otherwise (including any implied warranties of satisfactory quality or fitness for a particular purpose or non-infringement) are excluded to the extent permitted by law. In particular, we:<br>

            (a)do not make any commitments about the content or data within the {{ themeData.saasMerNameEn }} Portal, the specific functions of the {{ themeData.saasMerNameEn }} Portal or its accuracy, reliability, availability or ability to meet your needs;<br>
            (b)do not guarantee that the {{ themeData.saasMerNameEn }} Portal will operate uninterrupted or error-free, that it will always be available, that the information it contains is current or up-to-date, that it will be free from bugs or viruses, or never be faulty; <br>
            (c)may occasionally have to interrupt your use of the {{ themeData.saasMerNameEn }} Portal for operational, security or other reasonable reasons. In such a case, we will restore access as quickly as practicable.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">21.5 Financial advice.<br></span>21.5You must not treat any information or comments by {{ themeData.saasMerNameEn }} as financial advice. You should consider obtaining your own, independent advice with respect to any such information or comments and the use of the Services.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">21.6 Customer Responsibilities<br></span>The Customer:<br>

            (a)is solely responsible for the quality, accuracy, and completeness of the Customer Data transmitted via the Merchant Services Platform;<br>
            (b)must provide and/or obtain any equipment or telecommunications lines and links that may be necessary for it to use the Merchant Services Platform.<br>
            (c)is required to provide {{ themeData.saasMerNameEn }} with any additional information that {{ themeData.saasMerNameEn }} requests with regard to a Payment within two Business Days of {{ themeData.saasMerNameEn }}'s request for such information.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">21.7 Electronic Identification Checks<br></span>

            (a)The Customer acknowledges and agrees that {{ themeData.saasMerNameEn }} shall be permitted to carry out an electronic database search and search credit reference agencies in order to verify the Customer's, or any of its shareholders', identity and credit standing, as well as compliance with all Know Your Customer ("KYC") requirements and any Anti-Money Laundering/Counter-Terrorism Financing laws ("AML/CTF") of the jurisdiction that {{ themeData.saasMerNameEn }} operates in. The Customer acknowledges that that all KYC requirements need to be met before any transactions are processed and the Services executed. {{ themeData.saasMerNameEn }} may keep records of the contents and results of such searches in accordance with all current and applicable laws or as otherwise required.<br>
            (b)The Customer further acknowledges and agrees that {{ themeData.saasMerNameEn }} is permitted to carry out the necessary KYC screenings and transaction monitoring in accordance with the relevant AML/CTF laws on the Payer and Payee.<br>

          </p>
          <p class="detail">
            <span class="detailTitle">21.8 Treatment of Funds in Relation to AML/CTF Laws<br></span>Where electronic identification checks provided in clause 21.8 triggers an obligation for {{ themeData.saasMerNameEn }} to notify regulatory authorities, {{ themeData.saasMerNameEn }} reserves the right to withhold, reject, or delay the FX Transaction or Payment of any Customer funds, to accommodate the requirements of the relevant AML/CTF laws of the jurisdiction which {{ themeData.saasMerNameEn }} operates in.<br>
          </p>
        </div>
        <!--        <p class="navigationTitle"> 22.ANTI-BRIBERY, ANTI-CORRUPTION AND SANCTIONS<br/></p>-->
        <!--        <div style="margin-left: 20px">-->

        <!--          <p class="detail">-->
        <!--            22.1 You will (and will ensure that any person you use for the performance of your obligations under or in connection with these terms, including, Authorised Users, employees, agents, consultants and subcontractors will):<br/>-->

        <!--            (a)comply with all Applicable Law relating to Sanctions, bribery and corruption including the Hong Kong Prevention of Bribery Ordinance (Cap. 201), UK Bribery Act 2010 and the US Foreign Corrupt Practices Act (‘ABC Legislation’);<br/>-->
        <!--            (b)not do or omit to do anything likely to cause the other party to be in breach of any such ABC Legislation;<br/>-->
        <!--            (c)not give or receive any bribes, including in relation to any public official;<br/>-->
        <!--            (d)maintain a programme designed to ensure compliance with ABC Legislation, including an education and training programme and measures reasonably calculated to prevent and detect violations of ABC Legislation;<br/>-->
        <!--            (e)if requested, and at the other party’s reasonable cost, provide us with sufficient reasonable assistance to enable us to perform any actions required by any government or agency in any jurisdiction for the purpose of compliance with ABC Legislation or in connection with any investigation relating to ABC Legislation; <br/>-->
        <!--            (f)promptly notify us of any allegation of sanctions violation, fraud, bribery or corrupt or unlawful practices made against you in court, arbitration or administrative proceedings, or any investigation commenced in respect of the same.<br/>-->
        <!--          </p>-->
        <!--          <p class="detail">-->
        <!--            22.2 You undertake that you are not, and will procure that none of your Authorised Users, directors, officers, agents, employees or persons acting on behalf of the foregoing is, a Restricted Person and do not act directly or indirectly on behalf of a Restricted Person. You must supply to us details of any claim, action, suit, proceedings or investigation against you or such persons with respect to Sanctions by any Sanctions Authority to the extent permitted by law, promptly upon becoming aware.<br/>-->
        <!--          </p>-->
        <!--          <p class="detail">-->
        <!--            22.3 You will indemnify us against all liabilities, losses, and expenses which we have suffered as a result of a breach of this clause by the party.<br/>-->
        <!--          </p>-->
        <!--        </div>-->
        <p class="navigationTitle">22.MARKET DISRUPTION, ERRORS & FORCE MAJEURE<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">22.1 Force majeure.<br></span>Neither party will be responsible for any failure to fulfil any obligation for so long as, and to the extent to which the fulfilment of such obligation is impeded by a Force Majeure Event. The party subject to the Force Majeure Event will:<br>

            (a)promptly notify the other party of any circumstances which may result in failure to perform its obligations; <br>
            (b)use reasonable efforts to mitigate the effect of the Force Majeure Event on the performance of its obligations.<br>

          </p>
          <p class="detail">
            <span class="detailTitle">22.2 Market Disruption.<br></span>If, in our reasonable opinion, a Market Disruption occurs after we have provided you with an FX Exchange Rate, in relation to all or certain currency pairs affected by the Market Disruption, until we reasonably determine the period of Market Disruption has ended, we may revise any FX Exchange Rate, or any rate or margin component of any FX Exchange Rate, with immediate effect.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 22.3 Error in FX Exchange Rate.<br></span>If an FX Conversion is executed at an FX Exchange Rate that clearly and materially deviates from the market price, you must not rely on such obvious error. In such cases, we may declare that the FX Conversion is not binding and you will need to return to us any amounts made under the FX Conversion and we will reprocess such FX Conversion at the correct rate.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">22.4 Fee Payment.<br></span>This clause does not limit or otherwise affect your obligation to pay any fees or other charges or amounts due under these terms.<br>
          </p>
        </div>
        <p class="navigationTitle">23.GENERAL<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">23.1 Entire agreement.<br></span>These terms represent the entire agreement between the Parties and supersedes and extinguishes all previous agreements, representations, promises, and statements between us, whether written or oral, relating to its subject matter. Each party acknowledges that in entering into these terms it does not rely on, and will have no remedy in respect of, any representation or warranty (whether made innocently or negligently) that is not set out in these terms. Each party agrees that it shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in these terms.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.2 Complaints.<br></span>Please let us know if we have made a mistake or you feel that we have not met your expectations in the delivery of our Services. We have internal procedures for handling complaints fairly and promptly in accordance with our regulatory requirements. A copy of our complaints procedure is available upon request.<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 23.3 Notices.<br></span>All notices, demands and other communications provided for or permitted under these terms will be made in writing to the Parties at their registered addresses and will be sent by:<br>

            (a)registered or certified first-class mail;<br>
            (b)courier or overnight service or personal delivery;<br>
            (c)email;<br>

            and will be deemed received upon delivery. You agree and consent to receive notices and other communications from us by way of email and other electronic methods such through the {{ themeData.saasMerNameEn }} Portal.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.4 Relationship.<br></span>Except as expressly provided for in these terms or in any Additional Terms, nothing in these terms will be construed as creating a partnership or joint venture between the Parties, constitute any party being the agent of the other party, or authorise any party to make or enter into any commitments for or on behalf of the other party. Each party confirms it is acting on its own behalf and not for the benefit of any other person.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.5 Assignment.<br></span>You will not assign these terms, in whole or in part, without our prior written consent. Any attempt to do so will be void and constitute a material breach of these terms. We may assign these terms, in whole or in part, or subcontract our obligations under it, without your consent.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.6 Waiver.<br></span>No failure or delay by a party to exercise any right or remedy provided under these terms or by law, or a single or partial exercise of such right or remedy, will constitute a waiver of that or any other right or remedy, nor will it preclude or restrict the further exercise of that or any other right or remedy.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.7 Severability.<br></span>If any provision of these terms is determined to be invalid, unenforceable or illegal by any court of competent jurisdiction, it will be deemed to have been deleted without affecting the remaining provisions. If such provision would be valid, enforceable and legal if some part of it were modified or deleted, the provision will apply with the minimum modification necessary to make it legal, valid and enforceable.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.8 Non-solicitation.<br></span>During the term of these terms and for a period of one (1) year after termination, a party (‘First Party’) will not solicit the services of any staff of the other party (‘Second Party’) with whom the First Party has had any material dealing, within the preceding eighteen (18) months. However, for the avoidance of doubt, this shall not apply: (i) where prior written consent has been sought by the First Party from the Second Party; or (ii) in relation to any public recruitment campaign by or on behalf of the First Party which is not specifically targeted at any staff of the Second Party. This clause 24.8 shall survive termination of these terms.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.9 Third party rights.<br></span>These terms are made specifically between and for the benefit of the Parties, and the provisions of the Contracts (Rights of Third Parties) Ordinance (Cap 623) do not apply to these terms. No person who is not a party to these terms (whether or not such person is named, referred to, or otherwise identified, or forms part of a class of persons so named, referred to or identified in these terms) shall have any right under any Applicable Law, including the Contracts (Rights of Third Parties) Ordinance or otherwise to enforce these terms or to enjoy the benefit of any term of these terms. Neither party may declare itself a trustee of the rights under it for the benefit of any third party.<br>

          </p>
          <!--          <p class="detail">-->
          <!--            <span class="detailTitle">24.10 Set-off.<br/></span>We may, at any time, set off any payment liability you have to us against any payment liability that we have to you, whether either liability is present or future, liquidated or unliquidated, and whether or not either liability arises under these terms. If the liabilities to be set off are expressed in different currencies, we may convert either liability at a market rate of exchange for the purpose of set-off. Our right to set off includes, but is not limited to, setting off the amount of any payment you have requested against any amount collected by us for you as part of the Collection Services.<br/>-->
          <!--          </p>-->
          <p class="detail">
            <span class="detailTitle">23.10 Order of payments.<br></span>We may determine the order of transactions we process with respect to your account, subject to any requirements at law. For example, if you have given us one or more Payment instructions without having paid sufficient funds into the Correspondent Account, we may use any funds we subsequently collect or receive for the purposes of one or more of those Payment instructions at our discretion, unless we accept instructions from you otherwise.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.11 Records.<br></span>You agree that, except in the case of manifest error, our records of your use of the Services and of transactions carried out through the {{ themeData.saasMerNameEn }} Portal is conclusive evidence of its contents.<br>

          </p>
          <!--          <p class="detail">-->
          <!--            <span class="detailTitle">24.13 Other languages.<br/></span>These terms are made in the English language. They may be translated to other languages for convenience only and in the event of any inconsistency the English language version will prevail.<br/>-->
          <!--          </p>-->
          <p class="detail">
            <span class="detailTitle">23.12 Governing law.<br></span>These terms will be governed by and constructed in accordance with the laws of Hong Kong. Any Dispute, controversy, difference or claim arising out of or relating to this contract, including the existence, validity, interpretation, performance, breach or termination thereof or any dispute regarding non-contractual obligations arising out of or relating to it shall be referred to and finally resolved by arbitration administered by the Hong Kong International Arbitration Centre ("HKIAC") under the HKIAC Administered Arbitration Rules in force when the Notice of Arbitration is submitted. The seat of arbitration shall be Hong Kong. The number of arbitrators shall be one and the arbitration proceedings shall be conducted in English.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.13 Counterparts<br></span>This Agreement may be signed in writing or electronically and in one or more counterparts, each of which will be deemed an original and all of which will together constitute one and the same instrument. If this Agreement is entered online by you, by clicking the button “I Confirm” or similar button, registering, accessing or using the Services (collectively, “Indicated Consent”), you enter into a legally binding agreement with {{ themeData.saasMerNameEn }} and you agree to accept the terms and rules set out in this Agreement. In the event that after having given your Indicated Consent, you and {{ themeData.saasMerNameEn }} agree to execute a physical paper form of this Agreement, the physical paper form of this Agreement will also be valid, and be retroactively effective as of the date when you first give your Indicated Consent. In the unlikely event that there is inconsistency between the online version and physical form of this Agreement, the online version will prevail.<br>
          </p>
        </div>
        <p class="navigationTitle">24.DEFINITIONS & INTERPRETATION<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">24.1 In these terms, the following definitions apply:<br></span>
            <span class="detailTitle">Actions</span>
            means any action, claim, demand or proceeding, in respect of any loss, damage, costs, fines, expenses and other liabilities of whatever nature (whether foreseeable or contingent and whether relating to a third-party claim), including in relation to any breach of contract, negligence, fraud, wilful misconduct, breach of statutory duty or other head of claim and however suffered, incurred or sustained.<br>

            <span class="detailTitle">Additional Terms</span>
            means any additional terms of use applicable to the use of our Services found on our website {{ themeData.website }} or via other sources that {{ themeData.saasMerNameEn }} provides, including any specific terms applicable to the use of our Services in a particular region.<br>

            <span class="detailTitle">API Documentation</span>
            means the documentation and guidelines applicable to the {{ themeData.saasMerNameEn }} APIs found on our website {{ themeData.website }} or via other sources that {{ themeData.saasMerNameEn }} provides.<br>

            <span class="detailTitle">Applicable Law</span>
            means all laws, legislation, regulations, binding codes of practice, or rules or requirements of any relevant Regulatory Body applicable to the activities undertaken or procured by either party under these terms, including those relating to: (i) money services business; (ii) payment services; (iii) anti-money laundering, know your customer, counter terrorist financing and sanctions requirements; (iv) data protection; (v) consumer protection, and (vi) any code of practice or guidance issued by any Regulatory Body with which reputable financial institutions in Hong Kong are required or accustomed to comply.<br>
            <span class="detailTitle">Authorised User</span>
            means any person (for example, any of your directors, officers, employees or professional advisors) appointed by you for the purposes set out in clause 7.1.<br>
            <span class="detailTitle">Booking Confirmation</span>
            means {{ themeData.saasMerNameEn }}'s response and confirmation to the Customer Request for a foreign currency conversion over {{ themeData.saasMerNameEn }}'s Portal.<br>

            <span class="detailTitle">Buy Currency</span>
            means a Supported Currency which the Customer buys from {{ themeData.saasMerNameEn }} as part of the Customer Request over {{ themeData.saasMerNameEn }}'s Portal.<br>
            <span class="detailTitle">Business Day</span>
            means any day when banks are generally open for business in Hong Kong (other than a Saturday, Sunday or public holiday in Hong Kong, or any day on which a typhoon signal number 8 or above, or a black rainstorm warning is hoisted in Hong Kong) and / or a calendar day with the exception of Saturdays, Sundays and public holidays in the Account Jurisdiction, on which the payment infrastructures of the Account Jurisdiction are open and banks carry out their regular business activities.<br>
            <span class="detailTitle">Collection Services</span>
            means the collection agent services provided by {{ themeData.saasMerNameEn }} to you under these terms relating to the collection of funds by {{ themeData.saasMerNameEn }} in a Supported Currency.<br>

            <span class="detailTitle">Confidential Information </span>
            means and includes all confidential information, whether verbal or written: (i) concerning the business and affairs of a party, that a party obtains or receives from the other party; or (ii) which arises out of the performance of any obligations under these terms.
            Confidential Information does not include information which is: (a) known by the receiving party without restriction in relation to disclosure prior to receipt from the disclosing party; (b) received from a third party who lawfully acquired it and who was under no obligation restricting its disclosure; (c) approved in writing for release from these terms by the disclosing party; (d) available in the public domain other than by breach of these terms; or (e) independently developed without access to any Confidential Information disclosed by the disclosing party.<br>
            <span class="detailTitle">Confirmation</span>
            means {{ themeData.saasMerNameEn }}’s response and confirmation to your instructions to us for a FX Conversion, a Payment, or both.<br>
            <span class="detailTitle">Conversion Date</span>
            means the date that bought funds in an FX Conversion is available to the Customer in cleared funds. Conversion Date for any currency pair the Parties elect to transact in must be a Business Day in both countries (i.e. the countries where the Parties are located to transact business).<br>
            <span class="detailTitle">Correspondent Account</span>
            means a bank account in {{ themeData.saasMerNameEn }}’s name into which you can send funds to pay for any FX Conversion or Payment transactions.<br>
            <span class="detailTitle">Customer Data</span>
            means information that describes your business and its operations, your products or services, and orders placed by your customers.<br>
            <span class="detailTitle">Customer Margin</span>
            means the amount specified as such in the {{ themeData.saasMerNameEn }} Portal that applies to you, or any other percentage or amount that we may notify you in writing from time to time.<br>

            <span class="detailTitle">Data</span>
            means all Customer Data, Personal Data, Payment Data and {{ themeData.saasMerNameEn }} Data.<br>
            <span class="detailTitle">Data Subject</span>
            means any natural person who can be identified, directly or indirectly, via an identifier such as a name, an identification number, location data, or via factors specific to the person's physical, physiological, genetic, mental, economic, cultural or social identity.<br>
            <span class="detailTitle">Data Protection Legislation</span>
            means applicable privacy and data protection laws and any applicable national implementing laws, regulations and secondary legislation in Hong Kong relating to the processing of Personal Data and the privacy of electronic communications, as amended, replaced or updated from time to time.<br>
            <span class="detailTitle">Direct Losses</span>
            means, in respect of any breach, those losses that the breaching party knew, or a reasonable person in the breaching party’s position ought reasonably to have known, either: (i) at the time of entering into these terms; or (ii) at the time of the breach, were reasonably probable to result from the breach. For the avoidance of doubt, the following will not be Direct Losses, loss of:
            goodwill; business; profit; operation time; reputation; opportunity; or corruption of data or information; or loss of anticipated savings, even if the breaching party was aware or ought reasonably to have been aware of the possibility that such loss or damage could occur.<br>

            <span class="detailTitle">Force Majeure Event</span>
            means an event beyond a party's reasonable control including: Black Swan events; strikes, lock- outs, labour troubles (but excluding strikes or other forms of industrial action by the employees, agents or subcontractors of that party); interruption or failure of a utility service including the internet, electric power, gas or water; riots, war, pandemic, or terrorist attack; nuclear, chemical or biological contamination; extreme abnormal weather conditions; the imposition of a sanction, embargo or breaking off of diplomatic relations; or any change in Applicable Law.<br>
            <span class="detailTitle">FX Base Rate</span>
            means the currency exchange base rate we have specified for a FX Conversion and Conversion Date having regard to the costs provided by our foreign exchange rate service providers, the interbank exchange rates and relevant market conditions.<br>
            <span class="detailTitle">FX Conversion</span>
            means an agreement between the Parties in which one currency is sold or bought against another currency at an agreed exchange rate through the {{ themeData.saasMerNameEn }} Portal.<br>

            <span class="detailTitle">FX Conversion Services</span>
            means the services provided by {{ themeData.saasMerNameEn }} under these terms relating to FX Conversion.<br>

            <span class="detailTitle">FX Exchange Rate</span>
            means the FX Base Rate plus any other margin that may be applicable to you, including the Customer Margin.<br>
            <span class="detailTitle">Global Account</span>
            means a unique account ledger registered under your name and which records the amount of funds collected from your payers by {{ themeData.saasMerNameEn }} for you as part of the Collection Services.<br>
            <span class="detailTitle">Group Company</span>
            means any entity in respect of which {{ themeData.saasMerNameEn }} or {{ themeData.saasMerNameEn }}’s ultimate holding company: (i) owns (directly or indirectly) more than fifty (50) percent of the voting rights or issued share capital; or (ii) can ensure that the activities and business of that entity are conducted in accordance with its wishes.<br>
            <span class="detailTitle">Hong Kong</span>
            means the Special Administration Region of Hong Kong, People’s Republic of China.
            <span class="detailTitle">Indirect or Consequential Losses</span>
            means those losses that are not Direct Losses.
            <span class="detailTitle">Insolvency Event</span>
            means: (i) any procedure commenced with a view to the winding-up or re- organisation of such party; (ii) any step taken or any procedure is commenced with a view to the appointment of an administrator, receiver, administrative receiver or trustee in bankruptcy in relation to such party or all or substantially all of its assets; (iii) the holder of any security over all or substantially all of the assets of such party takes any step to enforce that security; (iv) all or substantially all of the assets of such party is subject to attachment, sequestration, execution or any similar process; (v) such party is unable to pay its debts as they fall due; (vi) such party enters into, or any step is taken, whether by the board of directors of such party or otherwise, towards entering into a composition or arrangement with its creditors or any class of them, including a company voluntary arrangement or a deed of arrangement; or (vii) such party enters into, or any step is taken, whether by the board of directors of such party or otherwise, toward any analogous procedure under the laws of any jurisdiction to the procedures set out in (i) to (vi) above, and in each case other than for the sole purpose of a scheme for a solvent amalgamation of that other party with one or more other companies or the solvent reconstruction of that other party.<br>
            <span class="detailTitle">Intellectual Property Rights</span>
            means: (i) rights in, and in relation to, any patents, registered designs, design rights, trademarks, trade and business names (including goodwill associated with any trade marks or trade and business names), copyright and related rights, moral rights, databases, domain names, semi-conductor and other topography rights and utility models, and including registrations and applications for, and renewals or extensions of, such rights, and similar or equivalent rights or forms of protection in any part of the world; (ii) rights in the nature of unfair competition rights and to sue for passing off and for past infringement; and (iii) trade secrets, confidentiality and other proprietary rights, including rights to know how and other technical information.<br>
            <span class="detailTitle">Market Disruption</span>
            means any action, event or circumstance which, as determined by {{ themeData.saasMerNameEn }} in its sole and absolute discretion: (i) has the direct or indirect effect of hindering, limiting or restricting the ability of {{ themeData.saasMerNameEn }} or its Group Companies to obtain a firm quote of an offer price, convert any currency into any other currency, or to transfer any sum to any other country or within the same country; (ii) results in any purchase currency not being available in the interbank foreign exchange market in accordance with normal commercial practice; or (iii) constitutes unusual price volatility in the foreign exchange markets.<br>
            <span class="detailTitle">Merchant Services</span>
            Platform or "MSP" means the user interface that the Customer and its Authorised Users may use to access the {{ themeData.saasMerNameEn }} Portal and the Services.<br>
            <span class="detailTitle">Network Rules</span>
            means the guidelines, bylaws, rules, agreements and regulations imposed by the financial services providers that operate payment networks supported by {{ themeData.saasMerNameEn }} from time to time (including the card scheme operating rules for Visa, MasterCard, or American Express).<br>
            <span class="detailTitle">{{ themeData.saasMerNameEn }} API</span>
            means the technical interface setting out the protocols and specifications required to effect an integration of the Customer’s technical systems with the {{ themeData.saasMerNameEn }} Portal for Authorised Users to use the Services.<br>
            <span class="detailTitle">{{ themeData.saasMerNameEn }} Data</span>
            means details of transactions using {{ themeData.saasMerNameEn }} infrastructure, information used in fraud detection and analysis, aggregated or anonymised information generated in connection with the Services, and any other information created by or originating from {{ themeData.saasMerNameEn }} or the Services.<br>
            <span class="detailTitle">{{ themeData.saasMerNameEn }} Portal</span>
            means the proprietary technology and associated products (including but not limited to those found on the MSP and through an {{ themeData.saasMerNameEn }} API) devised by {{ themeData.saasMerNameEn }} to provide customers with Services including access to the Global Account.<br>
            <span class="detailTitle">{{ themeData.saasMerNameEn }} Account</span>
            means the electronic information profile that records your business details and that is used to log into and use the {{ themeData.saasMerNameEn }} Portal.<br>

            <span class="detailTitle">Payment</span>
            means each and every individual payment to be made for you by {{ themeData.saasMerNameEn }} to a third party payee through the {{ themeData.saasMerNameEn }} Portal as part of the Payment Services.<br>

            <span class="detailTitle">Payment Data</span>
            means payment account details, information communicated to or by financial services providers, financial information specifically regulated by Applicable Laws and Network Rules, and any other transactional information generated as part of the use of our Services.<br>

            <span class="detailTitle">Payment Date</span>
            means the date nominated by you in your instructions to us through the {{ themeData.saasMerNameEn }} Portal, for the Payment to be transferred from {{ themeData.saasMerNameEn }} to the payee.<br>
            <span class="detailTitle">Payment Services</span>
            means, in connection with any Payments to be made in a Supported Currency to a payee by {{ themeData.saasMerNameEn }} in accordance with the Payment instructions provided by you, the payment agent services provided to you by {{ themeData.saasMerNameEn }} under these terms.
            <span class="detailTitle">Personal Data</span>
            means information that identifies a specific living person (not a company, legal entity, or machine) that is collected, transmitted to or accessible through the Services and as otherwise defined in the Data Protection Legislation.<br>

            <span class="detailTitle">Regulatory Body</span>
            means any person or regulatory body concerned with the creation, enforcement or supervision of, or compliance with Applicable Law, including the Customs & Excise Department of Hong Kong and any regulatory body which replaces it.<br>

            <span class="detailTitle">Restricted Person</span>
            means a person that is (i) listed on, or owned or controlled by a person listed on any Sanctions List; (ii) located in, incorporated under the laws of, or owned or controlled by, or acting on behalf of, a person located in or organised under the laws of a country or territory that is the target of country-wide Sanctions; or (iii) otherwise a target of Sanctions.<br>

            <span class="detailTitle">Sanctions</span>
            means any economic sanctions laws, regulations, embargoes or restrictive measures administered, enacted or enforced by: (i) Hong Kong; (ii) the United States; (iii) the United Nations; (iv) the European Union; (v) the United Kingdom; (vi) any other jurisdiction in which {{ themeData.saasMerNameEn }} operates; or (vii) the respective governmental institutions and agencies of any of the foregoing, including without limitation, the Office of Foreign Assets Control of the US Department of Treasury (‘OFAC’), the United States Department of State, and Her Majesty’s Treasury (together ‘Sanctions Authorities’).<br>
            <span class="detailTitle">Sanctions List</span>
            means the Specially Designated Nationals and Blocked Persons list issued by OFAC, the Consolidated List of Financial Sanctions Targets issued by Her Majesty’s Treasury, or any similar list issued or maintained or made public by any of the Sanctions Authorities.<br>
            <span class="detailTitle">Sell Currency</span>
            means a Supported Currency which the Customer sells to {{ themeData.saasMerNameEn }} as part of the Customer Request over {{ themeData.saasMerNameEn }}'s portal.<br>
            <span class="detailTitle">Services</span>
            means the services offered by {{ themeData.saasMerNameEn }} to the Customer under these terms, including Collection Services, FX Conversion Services and Payment Services provided through the {{ themeData.saasMerNameEn }} Portal.<br>
            <span class="detailTitle">Service Fees</span>
            means all fees applicable to the use of each of the Services.
            <span class="detailTitle">Settlement Cut-off</span>
            means the time and date where payment of any monies in cleared funds under these terms is due to {{ themeData.saasMerNameEn }}. Generally, it is one Business Day prior to the relevant Payment Date with respect to a Payment.<br>
            <span class="detailTitle">Source Currency</span>
            means a Supported Currency which is nominated by the Customer in the Customer Request, to fund a payment, over {{ themeData.saasMerNameEn }}'s Portal.<br>

            <span class="detailTitle">Spot Deal</span>
            refers to a transaction between the Parties in which one currency is sold or bought against another currency, in real time, at an agreed FX Exchange Rate, but the settlement of such transaction shall be at or before the Settlement Cut-Off (such timing may be varied subject to market requirement, upon which {{ themeData.saasMerNameEn }} shall notify the Customer with at least 5 Business Day's advance notice).<br>

            <span class="detailTitle">Supported Currency</span>
            means, in respect of each feature of a Service, each currency approved by {{ themeData.saasMerNameEn }} from time to time that can be collected, exchanged and/or paid out using that feature through the {{ themeData.saasMerNameEn }} Portal.<br>

            <span class="detailTitle">User Profile</span>
            means, with respect to each Authorised User, the electronic information profile that records the Authorised User’s details and that is used by them to log into and use the {{ themeData.saasMerNameEn }} Portal on your behalf.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">24.2 Unless the context otherwise requires:<br></span>

            (a)use of the singular includes the plural and vice versa;<br>
            (b)a reference to a statute or statutory provision is a reference to it as amended, extended or re-enacted from time to time;<br>
            (c)any phrase introduced by the terms ‘including’, ‘include’, ‘in particular’, ‘for example’ or any similar expression will be construed as illustrative and will not limit the sense of the words preceding those terms.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">24.3 References to the Parties will include any successors, permitted assignees or transferees where permitted by these terms.<br></span>The word ‘person’ will be deemed to include a body corporate, partnership or unincorporated association. References to any party indemnifying any person against any costs, claims or liabilities include indemnifying against any and all liabilities, losses and expenses, as well as (to the fullest extent, but only if permitted by Applicable Law) any civil or criminal fines imposed by any relevant Regulatory Body and any reasonably incurred legal fees, costs and expenses.<br>
          </p>
          <p class="detail">
            <span class="detailTitle">24.4 In this Agreement where the context admits:<br></span>

            (a)reference to a statutory provision includes reference to:<br>
            <span class="use-two-title">(i) any order, regulation, statutory instrument or other subsidiary legislation at any time made under it for the time being in force (whenever made); <br></span>
            <span class="use-two-title">(ii) any modification, amendment, consolidation, re-enactment or replacement of it or provision of which it is a modification, amendment, consolidation, re-enactment or replacement except to the extent that any modification, amendment, consolidation, re-enactment or replacement made after the date of this Agreement would increase the liability of any of the Parties hereto;<br></span>
            (b)reference to a Clause, Schedule or Paragraph is to a clause, schedule or a paragraph of a schedule of or to this Agreement respectively;<br>
            (c)reference to the Parties to this Agreement includes their respective successors, permitted assigns and personal representatives;<br>
            (d)reference to any Party to this Agreement comprising more than one person includes each person constituting that Party;<br>
            (e)reference to any gender includes the other gender;<br>
            (f)reference to persons includes bodies corporate or unincorporated;<br>
            (g)reference to any professional firm or company includes any firm or company effectively succeeding to the whole, or substantially the whole, of its practice or business;<br>
            (h)all warranties, representations, agreements and obligations expressed to be given or entered into by more than one person are given or entered into jointly and severally by the persons concerned;<br>
            (i)the index, headings and any descriptive notes are for ease of reference only and will not affect the construction or interpretation of this Agreement;<br>
            (j)the "agreed form" in relation to any document means the form agreed among the Parties, for the purposes of identification only, initialled by or on behalf of the Parties;<br>
            (k)all references to documents in the "agreed form" shall mean a document in a form agreed by the Parties and initialled by each of them for the purpose of identification;<br>
            (l)reference to writing or written includes fax and email;<br>
            (m)any obligation in this Agreement on a person not to do something includes an obligation not to agree or allow that thing to do be done;<br>
            (n)where the context permits, other and otherwise are illustrative and shall not limit the sense of the words preceding them;<br>
            (o)references to time of day are, unless the context requires otherwise, to Hong Kong time and references to a day are to a period of 24 hours running from midnight on the previous day.<br>
          </p>
        </div>
      </div>
    </a-card>
  </div>

</template>

<script>
import { reactive, toRefs, inject } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'TermsOfServiceEN',
  setup () {
    const router = useRouter()
    const state = reactive({
      themeData: inject('$themeData')
    })

    const usePolicy = () => {
      const usePolicyUrl = router.resolve({ name: 'usePolicy' })
      window.open(usePolicyUrl.href, '_blank')
    }

    return {
      usePolicy,
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
p.detail{margin-top: 20px;}
p.navigationTitle{margin-top: 20px;font-size: 18px;color: black;font-weight: bold;}
span.detailTitle{font-size: 15px;color: black;font-weight: bold;margin-top: 10px}
span.use-two-title {
  margin-left: 20px;
}
</style>
